import { WindowActionTypes, IWindowParams, WindowActionShow, WindowActionHide } from "../../types/window";

export const showWindow = (params: IWindowParams): WindowActionShow  => {
    return {
        type: WindowActionTypes.SHOW_WINDOW,
        payload: {
            show: true,
            ...params
        }
    }
};

export const hideWindow = (): WindowActionHide => {
    return {
        type: WindowActionTypes.HIDE_WINDOW
    }
};
