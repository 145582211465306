import React, { useEffect } from 'react';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { RouterProvider } from 'react-router-dom'
import router from './../../router';

import WindowStore from './components/WindowStore/WindowStore';
import Footer from './components/Footer';
import { getClassName } from '../../utils/className';
import { useActions } from '../../hooks/useActions';
import { isAdaptive } from '../../hoc/isAdaptive';

import style from './App.module.css'


const App: React.FC = () => {

    const { fetchContacts, fetchCopyright } = useActions();
    const { scrollBlock } = useTypedSelector((state) => state.window);


    useEffect(() => {
        fetchContacts();
        fetchCopyright();
    }, [])

    const cls = getClassName({
        [style.root]: true,
        [style.blockScroll]: scrollBlock,
        'root-container': true
    })


    return (
        <>
            <div className={cls}>
                <div>
                    <RouterProvider router={router} />
                </div>
                <Footer/>
            </div>
            <WindowStore />
        </>
    )
}

export default isAdaptive(App);
