import React  from "react";

import Card from '../../../../../common/Card';
import { ITariffs } from '../../../../../../types/tariffs';
import Button from '../../../../../common/Button';
import ConnectTvAndInternet from "../../../ConnectEthernet/ConnectTvAndInternet";
import { useActions } from "../../../../../../hooks/useActions";

import styles from './TariffItem.module.css'


const priceTypes = {
    day: 'сут',
    month: 'мес',
}
const TariffItem: React.FC<ITariffs> = (props ) => {

    const { showWindow } = useActions();
    const priceType = priceTypes[props.price_type];

    const HandleClickConnectInternet = () => {

        const content = <ConnectTvAndInternet name={props.name} id={'inet'} />
        showWindow({
            content,
            title: 'Подключить интернет',
            showCloseButton: true,
            header: true,
            scrollBlock: false
        })
    }

    return (
        <Card>
            <div className={styles.root}>
                <div>
                    <div className={styles.name}>
                        {props.name}
                    </div>
                    <div className={styles.line} />
                    <div className={styles.price}>
                    <span>
                        {props.price} руб
                    </span>
                        <span className={styles.priceType}>
                        /{priceType}
                    </span>
                        <div className={styles.speed}>
                            {`Скорость ${props.speed}`}
                        </div>
                    </div>
                </div>
                <div className={styles.button}>
                    <Button theme={'primary'} size={'small'}  onClick={HandleClickConnectInternet} >Подключить</Button>
                </div>
            </div>

        </Card>
    );
}
export default TariffItem
