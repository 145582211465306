import React from 'react';
import style from './PaymentError.module.css'

export default function PaymentError ()  {
    setTimeout(function(){
        location.href = '/lk';
    }, 10000);

    return (
        <div className={style.root}>
            <div className={style.test}>Оплата завершилась ошибкой...</div>
        </div>
    )
}