const actionTypes = {
    ALL_TV_PACKETS: 'ALL_TV_PACKETS',
    ALL_TV_PACKETS_SUCCESS: 'ALL_TV_PACKETS_SUCCESS',
    ALL_TV_PACKETS_ERROR: 'ALL_TV_PACKETS_ERROR',
    SELECTED_PACKET: 'SELECTED_PACKET',
}


const initialState = {
    allTvInfo: {
        count: 0,
        basePackets: null,
        extraPackets: null
    },
    id: null,
    extraId: {},
    loading: false,
    error: null,
}

export const allTvPacketReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ALL_TV_PACKETS: {
            return {
                ...state,
                loading: true
            }
        }
        case  actionTypes.ALL_TV_PACKETS_SUCCESS: {
            return  {
                ...state,
                allTvInfo: action.payload,
                id: action.payload.id,
                extraId: action.payload.extraId,
                loading: false,

            }

        }
        case actionTypes.ALL_TV_PACKETS_ERROR: {
            return  {
                ...state,
                error: action.payload.message,
                loading: false
            }
        }
        case actionTypes.SELECTED_PACKET: {
            return  {
                ...state,
                id: action.payload.selectedId
            }
        }

        default: {
            return state;
        }
    }
}

export default allTvPacketReducer;
