import React, { useEffect } from 'react';
import { useActions } from '../../../../../hooks/useActions';

import Button from '../../../../common/Button/Button';
import { useSelector } from 'react-redux';

import style from './Reserve.module.css'


export default function Reserve () {
    const { hideWindow, reservation, reservationEnable, reservationDisable } = useActions();

    const { active, text, minDisableDt, reservationAllowDisable} = useSelector(state => state.reservationInfo.info);

    useEffect(() => {
        reservation()
    }, []);

    const handlerReservationEnable = () => {
        reservationEnable()
        hideWindow()
    }
    const handlerReservationDisable = () => {
        reservationDisable()
        hideWindow()
    }

    if (!text) {
        return
    }


    return (
        <div className={style.root}>
            <div dangerouslySetInnerHTML={{__html: text}} className={style.content}></div>
            {!reservationAllowDisable && (
                <div>Можно отключить после: {minDisableDt}</div>
            )}
            <div className={style.buttonContainer}>
                <Button
                    activeButtonRed={!reservationAllowDisable}
                    className={style.button}
                    onClick={!active? handlerReservationEnable : handlerReservationDisable}
                    size="large"
                    shadow >
                    {!active? 'Включить': "Выключить"}
                </Button>
            </div>
        </div>
    );
}
