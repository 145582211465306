import React from "react";
import {Params, useMatches} from 'react-router-dom';

import style from './BreadCrumbs.module.css'

interface IBreadCrumbs {
    id: string;
    pathname: string;
    params: Params<string>;
    data: unknown;
    handle: {
        crumb: (param?: string) => React.ReactNode
    };
}


const BreadCrumbs = () => {
    // @ts-ignore
    const matches: IBreadCrumbs[] = useMatches();

    let crumbs = matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => match.handle.crumb(match.pathname));
    return (
        <div className={style.breadcrumb}>
            {crumbs.map((crumb, index) => (
                <div key={index}>{crumb}</div>
            ))}
        </div>
    )
}

export default BreadCrumbs
