import axios from '../../utils/axios';

const actionTypes = {
    ALL_TV_PACKETS: 'ALL_TV_PACKETS',
    ALL_TV_PACKETS_SUCCESS: 'ALL_TV_PACKETS_SUCCESS',
    ALL_TV_PACKETS_ERROR: 'ALL_TV_PACKETS_ERROR',
    SELECTED_PACKET: 'SELECTED_PACKET',
}

export const tvPackets = (param) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.ALL_TV_PACKETS
        })
        return axios
            .get(`/api/tv/tv_${param}_all_packet`)
            .then(res => {
                let basePacketId;
                res.data.basePackets.forEach(item =>{
                    if (item.enable === true) {
                        basePacketId = item.id
                    }
                })

                let extraPacketId = {};
                res.data.extraPackets.forEach(({id, enable}) =>{
                    extraPacketId[id] = enable
                })

                dispatch({
                    type: actionTypes.ALL_TV_PACKETS_SUCCESS,
                    payload: {
                        count: res.data.count,
                        basePackets: res.data.basePackets,
                        extraPackets: res.data.extraPackets,
                        id: basePacketId,
                        extraId: extraPacketId
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.ALL_TV_PACKETS_ERROR,
                    payload: {
                        message: error.payload.message
                    }
                })
            })
    }
}


export const setSelectedBasePacket = (packetId) => {
    return (dispatch) => {
        return axios
            .post('/api/tv/tv_change_base_packet', {packet_id: packetId})
            .then(() => {
                dispatch({
                    type: actionTypes.SELECTED_PACKET,
                    payload: {
                        selectedId: packetId
                    }
                })
            })
    }
}
export const deleteSelectedBasePacket = (packetId) => {
    return (dispatch) => {
        return axios
            .delete('/api/tv/tv_change_base_packet', {data: {packet_id: packetId}})
            .then(() => {
                dispatch({
                    type: actionTypes.SELECTED_PACKET,
                    payload: {
                        selectedId: null
                    }
                })
            })
    }
}


export const setSelectedExtraPacket = (extraId) => {
    return () => {
        return axios.post('/api/tv/tv_change_extra_packet', {packet_id: extraId.id})
            .then(response => {
                return response
            })
    }
}
export const deleteSelectedExtraPacket = (extraId) => {
    return () => {
        return axios.delete('/api/tv/tv_change_extra_packet', {data: {packet_id: extraId.id}})
            .then(response => {
                return response
            })
    }
}
