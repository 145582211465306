import React from "react";

import { useActions } from "../../../../hooks/useActions";
import ConnectNewFriend from "./component/ConnectNewFriend";
import  SalesBanner  from '../../../../static/images/sale.jpeg'

import styles from './Sales.module.css'


const Sales: React.FC = () => {
    const { showWindow } = useActions();

    const HandleClickConnectInternet = () => {

        const content = <ConnectNewFriend />
        showWindow({
            content,
            title: 'Подключить друга',
            showCloseButton: true,
            header: true,
            scrollBlock: true
        })
    }

    return (
        <div  onClick={HandleClickConnectInternet} className={styles.root}>
            <div className={styles.textContainer}>
                <div className={styles.titleText}>{process.env.REACT_APP_SALES_TITLE_TEXT || 'Пригласи друга'}</div>
                <div className={styles.text}>{process.env.REACT_APP_SALES_TEXT ||
                    'За каждого друга, подключившегося к Я.РУ по Вашему приглашению,\n' +
                    'Вы и Ваш друг получите по 300 рублей на лицевой счет'}
                </div>
                <div className={styles.text}>{process.env.REACT_APP_SALES_INFO || '* Не суммируется с другими акциями.'}</div>
            </div>
            <img src={SalesBanner} className={styles.banner} alt="Картинка с акциями"/>
        </div>
    );
}
export default Sales
