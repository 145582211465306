import * as AuthActionCreators from './auth'
import * as UserActionCreators from './user'
import * as LayoutActionCreators from './layout'
import * as NewsActionCreators from './news'
import * as OneNewsActionCreators from './oneNewsAction'
import * as TariffsActionCreators from './tariffs'
import * as ChannelsActionCreators from './channels'
import * as DocumentsActionCreators from './documents'
import * as MoneyPointActionCreators from './moneyPoint'
import * as BaseInfoActionCreators from './baseInfoAction'
import * as PaymentStatisticsCreator from './paymentsStatisticsAction'
import * as WindowsActionCreator from './windowActions'
import * as AuthActionCreator from './authAction'
import * as SendInfoCreator from './sendInfoAction'
import * as PaymentsCreateActionCreator from './paymentsCreacteAction'
import * as WhiteIpActionCreator from './whiteIpAction'
import * as PromisedPayActionCreator from './promisedPayAction'
import * as ReservationActionCreator from './reservationAction'
import * as TariffActionCreator from './tariffAction'
import * as TvBaseActionCreator from './allTvPacketAction'
import * as ListChannelsForIdActionCreator from './listChannelsForIdAction'
import * as PersonalInfoActionCreator from './personalInfoAction'
import * as ConnectNewUser from './connectNewUser';
import * as GetBalance from './getBalanceAction';
import * as TvProvidersActionCreator from './getTvProviderAction'
import * as TvInfo from './tvProviderInfoAction'

export default {
        ...AuthActionCreators,
        ...UserActionCreators,
        ...LayoutActionCreators,
        ...NewsActionCreators,
        ...TariffsActionCreators,
        ...ChannelsActionCreators,
        ...DocumentsActionCreators,
        ...MoneyPointActionCreators,
        ...BaseInfoActionCreators,
        ...PaymentStatisticsCreator,
        ...WindowsActionCreator,
        ...AuthActionCreator,
        ...SendInfoCreator,
        ...PaymentsCreateActionCreator,
        ...WhiteIpActionCreator,
        ...PromisedPayActionCreator,
        ...ReservationActionCreator,
        ...TariffActionCreator,
        ...TvBaseActionCreator,
        ...ListChannelsForIdActionCreator,
        ...PersonalInfoActionCreator,
        ...ConnectNewUser,
        ...OneNewsActionCreators,
        ...GetBalance,
        ...TvProvidersActionCreator,
        ...TvInfo,
};
