import React, {useEffect} from 'react';

import { useTypedSelector } from '../../../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../../../hooks/useActions';
import Button from '../../../../../../common/Button';

import style from './OneNews.module.css'

interface IOneNewsProps {
    id: number;
}

const OneNews: React.FC<IOneNewsProps> = (props)=> {

    const { hideWindow, oneNews } = useActions();
    const { data, loading } = useTypedSelector(state => state.oneNews)

    const htmlString = data.text

    useEffect(() => {
        oneNews((props.id))
    }, [])

    if (loading) {
        return <React.Fragment />
    }


    const handleCloseWindow = () => {
        hideWindow()
    }

    return (
        <>
            <div className={style.icon} style={{backgroundImage: `url(${data.icon_url})`}}></div>
            <div className={style.title}>{data.title}</div>
            <div className={style.date}>{`Опубликовано: ${data.date}`}</div>
            <div className={style.root}>
                <div className={style.text} dangerouslySetInnerHTML={{ __html: htmlString }}></div>
            </div>
            <div className={style.buttonContainer}>
                <Button className={style.button} size={'large'} onClick={handleCloseWindow}>Закрыть</Button>
            </div>
        </>
    )
}

export default OneNews;
