import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../../hooks/useActions';

import { ReactComponent as Frame } from '../../../../../static/images/Frame.svg'
import { getClassName } from '../../../../../utils/className';
import Button from '../../../../common/Button/Button';

import style from './PromisedPaymentWindow.module.css'


export default  function PromisedPaymentWindow() {
    const { hideWindow, promisedPay, confirmedPay } = useActions();

    const [statusPay, setStatusPay] = useState(false);
    const { text, allow, message }  = useSelector(state => state.promisedPay.info);

    useEffect(() => {
        promisedPay()
    }, [])

    if (!text) {
        return;
    }


    const closeWindowHandler = () => {
        hideWindow()
    }

    const handleSubmitPay = () => {
        setStatusPay(true)
        confirmedPay({
            status: statusPay
        })
        hideWindow()
    }
    const csl = getClassName({
        [style.buttonDisabled]: !allow,
        [style.button]: true
    })

    return (
        <div className={style.root}>
            <div className={style.iconContainer}>
                <Frame className={style.icon} />
            </div>
            <div className={style.content} dangerouslySetInnerHTML={{ __html: text }}>
            </div>
            {!allow && (
                <div className={style.promisedMessage}>{message}</div>
            )}
            <div className={style.buttonContainer}>
                <div className={csl}>
                    <Button onClick={handleSubmitPay} size="large">
                        Да
                    </Button>
                </div>
                <div className={style.button}>
                    <Button onClick={closeWindowHandler} size="large">
                        Нет
                    </Button>
                </div>

            </div>
        </div>
    );
}
