import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from 'leaflet';
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import Title from "../../../common/Title";

import styles from './Contacts.module.css'

const Contacts: React.FC = () => {

    const { contacts, loading } = useTypedSelector(state => state.layout);
    const iconsMarker = L.icon({ iconUrl: icon, shadowUrl: iconShadow});

    return (
        <>
            <Title>Контакты</Title>

            <div className={styles.contacts}>
                <div>{contacts.ur_info.name}</div>
                <div>{`ИНН: ${contacts.ur_info.inn} ОГРН: ${contacts.ur_info.ogrn} КПП: ${contacts.ur_info.kpp}`}</div>
                <div>
                    {contacts.address}
                </div>
                <div>
                    {contacts.phones.map((item, index) => (
                        <a className={styles.link} href={`tel:${item.url}`} key={index}>{item.string} &nbsp; &nbsp;</a>
                    ))}
                </div>
                <div>
                    <a className={styles.link} href={`mailto:${contacts.email}}`}>{contacts.email}</a>
                </div>
            </div>
            {!loading && (
                <MapContainer
                    attributionControl={false}
                    className={styles.map}
                    center={[contacts.coordinates.lat, contacts.coordinates.lon]}
                    zoom={17} scrollWheelZoom={true}
                >
                    <TileLayer
                        url="/tiles/main/{z}/{x}/{y}.png"
                    />
                    <Marker position={[contacts.coordinates.lat, contacts.coordinates.lon]} icon={iconsMarker}>
                        <Popup>
                            ул. им.Профессора Рудакова 15, офис 25
                        </Popup>
                    </Marker>
                </MapContainer>
            )}
        </>
    );
}
export default Contacts
