import React from 'react';

import Card from '../../../../../common/Card';
import { ITv } from '../../../../../../types/channels';
import Button from '../../../../../common/Button';
import ConnectTvAndInternet from "../../../ConnectEthernet/ConnectTvAndInternet";
import { useActions } from "../../../../../../hooks/useActions";
import ListChannels from '../../../../../ListTv/components/MainChannel/components/ListСhannels/ListChannels';

import styles from './ChannelItem.module.css'
import {number} from 'prop-types';
import {de} from 'date-fns/locale';


const priceTypes = {
    day: 'сут.',
    month: 'мес.',
}

const ChannelItem: React.FC<ITv> = (props ) => {

    const { showWindow, ListTvInfo } = useActions();
    // @ts-ignore
    const priceType = priceTypes[props.price_type];

    const handleClick = () => {
        const content = <ConnectTvAndInternet name={props.name} id={'tv'} />
        showWindow({
            content,
            title: 'Подключить телевидение',
            showCloseButton: true,
            header: true,
            scrollBlock: true
        })
    }


    const handleClickListChannels = async (id: number): Promise<void> => {
        const provider: string = 'default'
        try {
            await ListTvInfo({id, provider})
            // @ts-ignore
            const content = <ListChannels />
            showWindow({
                content,
                title: props.name,
                showCloseButton: true,
                header: true,
                scrollBlock: true
            })
        } catch (e) {

        }


    }

    return (
        <Card>
            <div className={styles.root}>
                <div className={styles.left}>
                    <div>
                        <div className={styles.name}>
                            {props.name}
                        </div>
                        <div className={styles.channels} onClick={() => {handleClickListChannels(props.providerId)}}>
                            Список каналов
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.price}>
                        <div className={styles.amount}>
                            {props.price}
                        </div>
                        <div className={styles.currency}>
                            <div>
                                руб.
                            </div>
                            <div>
                                {priceType }
                            </div>
                        </div>
                    </div>
                    <Button theme={'primary'} size={'small'} onClick={handleClick}>Подключить</Button>
                </div>

            </div>
        </Card>
    );
}
export default ChannelItem
