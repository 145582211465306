import axios from '../../utils/axios';

const actionTypes = {
    PROMISED_PAY: 'PROMISED_PAY',
    PROMISED_PAY_SUCCESS: 'PROMISED_PAY_SUCCESS',
    PROMISED_PAY_ERROR: 'PROMISED_PAY_ERROR',
    CONFIRMED_PAY: 'CONFIRMED_PAY',
    CONFIRMED_PAY_ERROR: 'CONFIRMED_PAY_ERROR',
}

export const promisedPay = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.PROMISED_PAY
        })
        return axios
            .get('/api/lk/promised_pay')
            .then(res => {
                dispatch({
                    type: actionTypes.PROMISED_PAY_SUCCESS,
                    payload: {
                        allow: res.data.allow,
                        count: res.data.count,
                        text: res.data.text,
                        message: res.data.message
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.PROMISED_PAY_ERROR,
                    payload: {
                        message: error.payload.message
                    }
                })
            })
    }
}

export const confirmedPay = (params) => {
    return (dispatch) => {
        return axios
            .post('/api/lk/promised_pay', {
                status: params.status
            })
            .then(res => {
                dispatch({
                    type: actionTypes.CONFIRMED_PAY,
                    payload: {
                        status: res.status
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.CONFIRMED_PAY_ERROR,
                    payload: {
                        message: error.payload.message
                    }
                })
            })
    }
}