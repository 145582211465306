import axios from '../../utils/axios';


const actionType = {
    TV_INFO: 'TV_INFO',
    TV_INFO_SUCCESS: 'TV_INFO_SUCCESS',
    TV_INFO_ERROR: 'TV_INFO_ERROR'
}

export const tvInfo =  (providerId) => {
    return async (dispatch) => {
        try  {
            dispatch({type: actionType.TV_INFO})
            const response = await axios.get(`/api/tv/tv_${providerId}_info`)
            dispatch({type: actionType.TV_INFO_SUCCESS, payload: {message: response.data.message, footer: response.data.footer}})
        } catch (error) {
            dispatch({
                type: actionType.TV_INFO_ERROR,
                payload: 'Ошибка при загрузке списка'
            })
        }
    }
}