import axios from './../../utils/axios';
import { Dispatch } from 'redux';
import { FetchTerminalsResponse, FetchPaymentMethodsResponse, MoneyPointAction, MoneyPointActionTypes } from '../../types/moneyPoint';

export const fetchTerminals = () => {
    return async (dispatch: Dispatch<MoneyPointAction>) => {
        try  {
            dispatch({type: MoneyPointActionTypes.FETCH_TERMINALS})
            const response = await axios.get<FetchTerminalsResponse [] >('/api/site/get_terminal')
            dispatch({type: MoneyPointActionTypes.FETCH_TERMINALS_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({
                type: MoneyPointActionTypes.FETCH_TERMINALS_ERROR,
                payload: 'Ошибка при загрузке списка'
            })
        }
    }
}
export const fetchPaymentMethods =  () => {
    return async (dispatch: Dispatch<MoneyPointAction>) => {
        try  {
            dispatch({type: MoneyPointActionTypes.FETCH_PAYMENT_METHODS})
            const response = await axios.get<FetchPaymentMethodsResponse>('/api/site/get_payment_methods')
            dispatch({type: MoneyPointActionTypes.FETCH_PAYMENT_METHODS_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({
                type: MoneyPointActionTypes.FETCH_PAYMENT_METHODS_ERROR,
                payload: 'Ошибка при загрузке списка'
            })
        }
    }
}

