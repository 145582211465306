const actionTypes = {
    RESERVATION: 'RESERVATION',
    RESERVATION_SUCCESS: 'RESERVATION_SUCCESS',
    RESERVATION_ERROR: 'RESERVATION_ERROR',
    ACTIVE: 'ACTIVE'
}

const initialState = {
    info: {
        active: false,
        count: 0,
        text: null,
        minDisableDt: '',
        reservationAllowDisable: true,
    },
    loading: false,
    error: null,
}

export const reservationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESERVATION: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.RESERVATION_SUCCESS: {
            return {
                ...state,
                info: action.payload,
                loading: false
            }
        }
        case actionTypes.RESERVATION_ERROR: {
            return {
                ...state,
               error: action.payload.message,
                loading: false
            }
        }
        case actionTypes.ACTIVE: {
            return {
                ...state,
                active: action.payload.active,
                loading: false
            }
        }
        default: {
            return state
        }
    }
}

export default reservationReducer;