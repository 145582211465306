const actionTypes = {
    LIST_CHANNELS: 'LIST_CHANNELS',
    LIST_CHANNELS_SUCCESS: 'LIST_CHANNELS_SUCCESS',
    LIST_CHANNELS_ERROR: 'LIST_CHANNELS_ERROR',
}

const initialState = {
    infoChannels: {
        count: 0,
        channels: null,
        name: '',
        price: '',
        priceType: '',
        short: ''
    },

    loading: false,
    error: null,
}

export const listChannelsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LIST_CHANNELS: {
            return {
                ...state,
                loading: true
            }
        }
        case  actionTypes.LIST_CHANNELS_SUCCESS: {
            return  {
                ...state,
                infoChannels: action.payload,
                loading: false,
            }

        }
        default: {
            return state;
        }
    }
}