import React from 'react';

import { INews } from "../../../../../../types/news";
import Card from '../../../../../common/Card';
import { useActions } from '../../../../../../hooks/useActions';
import OneNews from './component/OneNews';

import styles from './NewsItem.module.css'



const NewsItem: React.FC<INews> = (props ) => {
    const { showWindow } = useActions();

    const handleNewsClick = () => {
        if (props.showModal) {
            const content = <OneNews id={props.id}/>
            showWindow({
                content,
                title: ' ',
                showCloseButton: false,
                header: true,
                scrollBlock: true
            })
        }
    }

    return (
        <Card>
            <div onClick={handleNewsClick} className={styles.root}>
                <div className={styles.icon} style={{backgroundImage: `url(${props.icon_url})`}}>
                </div>
                <div className={styles.title}>
                    {props.title}
                </div>
                <div className={styles.date}>{`Опубликовано: ${props.date}`}</div>
                <div className={styles.text}>
                    {props.text}
                </div>
            </div>
        </Card>
    );
}
export default NewsItem
