import React, {useEffect, useState} from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';

import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import Title from "../../../common/Title";
import NewsItem from "./components/NewsItem";
import { isAdaptive } from '../../../../hoc/isAdaptive';

import styles from './News.module.css'

interface viewTypeProps {
    viewType: string
}

const News: React.FC<viewTypeProps> = ({ viewType }) => {
    const { fetchNews } = useActions();
    const { data } = useTypedSelector(state => state.news)
    const [perPage, setPerPage] = useState(3)


    useEffect(()=> {
        fetchNews()
    },[]);

    useEffect(()=> {
        if (viewType === 'mobile') {
            setPerPage(1);
        } else if (viewType === 'tablet'){
            setPerPage(2);
        } else if (viewType === 'desktop') {
            setPerPage(3);
        }
    },[viewType])


    return (
        <>
            <Title>Новости</Title>
            <div className={styles.container}>
                <Splide hasTrack={ false } options={{
                    type   : 'loop',
                    perPage: perPage,
                    perMove: 1,
                    gap: '30px',
                    pagination: false,
                    isNavigation: true,
                }}>
                    <SplideTrack>
                        {data.news && data.news.map(item =>
                            <SplideSlide key={item.id}>
                                <NewsItem
                                    id={item.id}
                                    title={item.title}
                                    text={item.text}
                                    icon_url={item.icon_url}
                                    date={item.date}
                                    showModal={item.showModal}
                                />
                            </SplideSlide>
                        )}
                    </SplideTrack>
                </Splide>
            </div>
        </>
    );
}
export default isAdaptive(News)
