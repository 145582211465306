import axios from '../../utils/axios';

const actionTypes = {
    RESERVATION: 'RESERVATION',
    RESERVATION_SUCCESS: 'RESERVATION_SUCCESS',
    RESERVATION_ERROR: 'RESERVATION_ERROR',
    ACTIVE: 'ACTIVE'
}

export const reservation = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.RESERVATION,
        })
        return axios
            .get('/api/lk/reservation')
            .then(res => {
                dispatch({
                    type: actionTypes.RESERVATION_SUCCESS,
                    payload: {
                        active: res.data.active,
                        count: res.data.count,
                        text: res.data.text,
                        reservationAllowDisable: res.data.reservation_allow_disable,
                        minDisableDt: res.data.min_disable_dt
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.RESERVATION_ERROR,
                    payload: {
                        message: error.payload.message
                    }
                })
            })
    }
}

export const reservationEnable = () => {
    return (dispatch) => {
        return axios
            .post('api/lk/reservation')
            .then(res => {
                dispatch(reservation())
                // dispatch({
                //     type: actionTypes.ACTIVE,
                //     payload: {
                //         active: res.data.active
                //     }
                // })
            })
    }
}

export const reservationDisable = () => {
    return (dispatch) => {
        return axios
            .delete('api/lk/reservation')
            .then(res => {
                dispatch(reservation())
                // dispatch({
                //     type: actionTypes.ACTIVE,
                //     payload: {
                //         active: res.data.active
                //     }
                // })
            })
    }
}