const actionTypes = {
    BASE_INFO: 'BASE_INFO',
    BASE_INFO_SUCCESS: 'BASE_INFO_SUCCESS',
    BASE_INFO_ERROR: 'BASE_INFO_ERROR',
}

const initialState =  {
    info: {
        fio: '',
        money: null,
        reservation_allow: true,
        promised_pay_allow: true,
        recomended_pay: true,
        сonnected: true,
        days_to_stop: null,
        worlds:  '',
        IP: null,
        reservation: true,
        promised_pay: true,
        promised_pay_size: null,
        tariff: null,
        balanceMessage: '',
        phone: ''
    },
    loading: false,
    error: null
}

export const baseInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BASE_INFO: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.BASE_INFO_SUCCESS: {
            return {
                ...state,
                info: action.payload,
                loading: false
            }
        }
        case actionTypes.BASE_INFO_ERROR: {
            return {
                ...state,
                error: action.payload.message,
                loading: false
            }
        }
        default: {
            return state;
        }
    }
}

export default baseInfoReducer;
