import React, { useEffect } from 'react';

import Title from '../../../common/Title';
import { useActions } from '../../../../hooks/useActions';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';

import style from './Documents.module.css'


const Documents: React.FC = () => {
    const { fetchDocuments } = useActions();
    const { data } = useTypedSelector(state => state.documents);

    useEffect(()=> {
        fetchDocuments()
    },[])


    return (
        <>
            <Title>Документы</Title>
            <div>
                {data.documents && data.documents.map(item => {
                    return (
                        <div key={item.id}>
                            <div  className={style.titleInfo}>{item.name}</div>
                            {item.content.map((item) => (
                                <div className={style.documentContainer} key={item.id}>
                                    <a href={item.url} target="_blank"><img className={style.icon} alt={'Картинка'} src={item.icon_url}/></a>
                                    <a href={item.url} target="_blank" className={style.documentText}>{item.title}</a>
                                </div>
                            ))}
                        </div>
                    )
                })}
            </div>
        </>
    );
}
export default Documents
