import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useActions } from '../../../hooks/useActions';

import FeedBackButton from './component/FeedBackButton';
import Button from '../../common/Button/Button';
import IconButton from '../../common/IconButton/IconButton';
import { ReactComponent as Icon } from '../../../static/images/Icon-Ghost_Big.svg';
import { ReactComponent as Pencil } from '../../../static/images/Pencil.svg';

import style from './Feedback.module.css'


export default function Feedback() {

    const { showWindow, logOut } = useActions();
    const { fio } = useSelector(state => state.baseInfo.info);

    const showWindowClick = () => {
        const content = <FeedBackButton/>;
        showWindow({
            content,
            title: 'Обратная связь',
            showCloseButton: true,
            header: true,
        })
    }

    const closeAuth = () => {
        logOut()
        window.location.assign('/login')
    }


    return(

            <div className={style.root}>
                <div>
                    <Button size={'large'} onClick={showWindowClick}>
                        Обратная&nbsp;связь
                    </Button>
                </div>

                <div className={style.navHome}>
                        <Link to='personal-info' className={style.link}>
                            <div className={style.fio}>{fio}</div>
                            <Pencil/>
                        </Link>
                    <IconButton theme="secondary" round={false} onClick={closeAuth}>
                        <Icon className={style.logout} />
                    </IconButton>
                </div>
            </div>

    );
}
