import React, {ReactNode} from 'react';

import { ReactComponent as CloseButton } from '../../../static/images/Closer.svg';

import style from './Window.module.css'

export interface IModal {
    title: string;
    showCloseButton: boolean;
    handleCloseButton: () => void;
    header: boolean;
    content: ReactNode | undefined;
    scrollBlock?: boolean;
    show: boolean;
}

const Window: React.FC<IModal> = (
    {
        show,
        content,
        title,
        showCloseButton,
        handleCloseButton,
        header}
) => {

    if(!show && !content) {
        return <React.Fragment />
    }

    const handleClose = () => {
        handleCloseButton()
    }

    return (
        <div className={style.container}>
            <div className={style.overlay}></div>
            <div className={style.window}>
                {header && (
                    <div className={style.header}>
                        {title && (
                            <div className={style.title}>{title}</div>
                        )}

                        {showCloseButton && (
                            <CloseButton className={style.closeButton} onClick={handleClose}/>
                        )}
                    </div>
                )}
                <div className={style.content}>
                    {content}
                </div>
            </div>
        </div>
    );
}

export default Window;
