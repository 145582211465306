import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../../../../hooks/useActions';

import Label from '../../../../../../common/Label/Label';
import Button from '../../../../../../common/Button/Button';
import SwitchToggle from '../../../../../../common/SwitchToggle';

import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';

import "react-datepicker/dist/react-datepicker.css";
import style from './TvTariffPlane.module.css'




export default function TvTariffPlane() {
    const { hideWindow, changeTariffInfo, tariffInfo } = useActions();

    const idTariff  = useSelector(state => state.baseInfo.info.tariff.id);
    const tariffs  = useSelector((state) => state.tariff.info.tariffs);
    const text  = useSelector(state => state.tariff.info.text);
    const [selectedId, setSelectedId] = useState(idTariff);
    let date = new Date();
    date.setDate(date.getDate() + 1);
    const [startDate, setStartDate] = useState(date);

    const handleToggle = (data) => {
        setSelectedId(data)
    };

    const applyChangeTariff = () => {
        changeTariffInfo({
            tariff_id: selectedId,
            start_date: startDate,
        })
        hideWindow()
    }

    useEffect(() => {
        tariffInfo()
    }, []);


    if (!tariffs && !text) {
        return;
    }


    return (
        <div className={style.root}>
            <div dangerouslySetInnerHTML={{__html: text}}></div>
            {tariffs.map(({name, price, price_type, id, speed, change_only_date, change_date_text}) => {
                return (
                    <div key={id} className={style.tariffContainer}>
                        <div>
                            <div className={style.tariffName}>{name}</div>
                            <div className={style.changeTariff}>{change_date_text}</div>
                            <div className={style.changeTariff}>{change_only_date}</div>
                        </div>
                        <div className={style.tariffInfo}>
                            <div>
                                <div className={style.priceContainer}>
                                    <div className={style.price}>{price}</div>
                                    <div className={style.priceType}>руб / {price_type === 'day'? 'сутки' : 'месяц'}</div>
                                </div>
                                <div className={style.tariffLine}></div>
                                <div className={style.tariffSpeed}>{speed}</div>
                            </div>
                                <SwitchToggle id={id} value={id === selectedId} enable={true} onClick={handleToggle} />
                        </div>
                    </div>
                )
            })}
            <div className={style.dataChoiceContainer}>
                <div>
                    <Label title='Дата начала перехода'>
                        <div className={style.picker}>
                            <DatePicker
                                className={style.pickerAlign}
                                locale={ru}
                                selected={startDate}
                                dateFormat="dd/MM/yyyy"
                                onChange={(date) => setStartDate(date)}
                                minDate={new Date()}
                                selectsStart
                                startDate={startDate}
                            />
                        </div>
                    </Label>
                </div>
            </div>
            <div className={style.buttonContainer}>
                <Button
                    className={style.button}
                    onClick={applyChangeTariff}
                    size="large"
                    shadow >
                    Применить
                </Button>
            </div>
        </div>
    );
}
