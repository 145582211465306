import React from 'react';
import { useActions } from '../../../../../../hooks/useActions';

import Button from '../../../../../common/Button/Button';

import  style from './ConfirmExtraPackets.module.css'


export default function ConfirmPackets ({ confirm, id }) {
    const { hideWindow } = useActions();

    const agreement = () => {
        confirm(id)
        hideWindow()

    }

    const disagreement = () => {
        hideWindow()
    }

    return (
        <div className={style.buttonContainer}>
            <Button className={style.button} size={'large'} onClick={agreement}>
                Да
            </Button>
            <Button className={style.button} size={'large'} onClick={disagreement}>
                Нет
            </Button>
        </div>
    )
}
