const actionTypes = {
    TARIFF: 'TARIFF',
    TARIFF_SUCCESS: 'TARIFF_SUCCESS',
    TARIFF_ERROR: 'TARIFF_ERROR',
}

const initialState = {
    info: {
        status: false,
        count: true,
        message: null,
        text: null,
        tariffs: null,
    },
    loading: false,
    error: null
}
export const tariffReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TARIFF: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.TARIFF_SUCCESS: {
            return {
                ...state,
                info: action.payload,
                text: action.payload.text,
                loading: false
            }
        }
        case actionTypes.TARIFF_ERROR: {
            return {
                ...state,
                error: action.payload.message,
                loading: false
            }
        }
        default: {
            return state;
        }
    }
}

export default tariffReducer;