const actionType = {
    TV_INFO: 'TV_INFO',
    TV_INFO_SUCCESS: 'TV_INFO_SUCCESS',
    TV_INFO_ERROR: 'TV_INFO_ERROR'
}

const initialState = {
    data: {
        message: null,
        footer: null,
    },
    loading: false,
    error: null
}


export const tvInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.TV_INFO: {
           return {
               ...state,
               loading: true
           }
        }
        case actionType.TV_INFO_SUCCESS: {
            return {
                ...state,
                data: action.payload,
                loading: true
            }
        }
        case actionType.TV_INFO_ERROR: {
            return {
                ...initialState,
                error: action.payload,
                loading: false
            }
        }
        default: {
            return state;
        }
    }
}

export default tvInfoReducer;