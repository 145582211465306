import React, {useCallback, useState} from 'react';

import { useActions } from '../../../../hooks/useActions';
import { IInternetConnect, NewUserDataItem } from '../../../../types/connectNewUser';
import Label from '../../../common/Label';
import Input from '../../../common/Input';
import Button from '../../../common/Button';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';

import style from './ConnectTvAndInternet.module.css'


interface IPropsTvAndInternet {
    name: string;
    id: string;
}

const ConnectTvAndInternet: React.FC<IPropsTvAndInternet> = ({ name, id }) => {

    const [token, setToken] = useState('');
    const [visible, setVisible] = useState(true);
    const handleChallengeHidden = useCallback(() => setVisible(false), []);

    const { connectNewInternet, hideWindow } = useActions();
    const data: NewUserDataItem[]  = [
        {
            objectKey: 'name',
            label: 'Имя'
        },
        {
            objectKey: 'phone',
            label: 'Телефон'
        },
        {
            objectKey: 'email',
            label: 'E-mail'
        },
        {
            objectKey: 'address',
            label: 'Адрес'
        },
    ];

    const [internetInfo, setInternetInfo] = useState((): IInternetConnect => {
        return data.reduce((acc, value) => {
            //@ts-ignore
            acc[value.objectKey] = ''
            return acc;
        }, {comment: '', tariff_name: name}) as IInternetConnect;
    });

    const [errorArrayField, setErrorArrayField] = useState<string[]>([]);

    const handleInputValue = (inputValue: string, name: string) => {
        setInternetInfo({
            ...internetInfo,
            [name]:  inputValue
        })
    };

    const handleSubmitFriendInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        const array =  [];

        for (let key in internetInfo){
            //@ts-ignore
            if (internetInfo[key] === '') {
                array.push(key)
            }

        }

        if (array.length === 0) {

            id === 'tv'?  connectNewInternet({
                message: internetInfo,
                token: token,
                path: 'siteRequestNewTVClient'
            }) : connectNewInternet({
                message: internetInfo,
                token: token,
                path: 'siteRequestNewClient'
            })
            hideWindow();
        }

        setErrorArrayField(array);


    }

    const handleFocusOnBlur = (name: string) => {

        const errorBlur =  [...errorArrayField];
        let indexField = errorBlur.indexOf(name);

        // @ts-ignore
        const value = internetInfo[name]
        if (value === '' && indexField === -1) {
            errorBlur.push(name)
        }
        if(value !== ''){
            errorBlur.splice(indexField, 1)
        }

        setErrorArrayField(errorBlur);

    }

    return (
       <>
           <form className={style.root}>
               <div className={style.container}>
                   {data.map(el => (
                       <div key={el.objectKey}>
                           <Label title={el.label}>
                               <Input onChange={handleInputValue} name={el.objectKey} onBlur={handleFocusOnBlur} />
                               {errorArrayField.includes(el.objectKey) && (<div className={style.alert}>{'Обязательное поле не заполнено!'}</div>)}
                           </Label>
                       </div>
                   ))}
               </div>
               <div className={style.comment}>
                   <Label title={'Коментарий'}>
                       <Input onChange={handleInputValue} name={'comment'} onBlur={handleFocusOnBlur} />
                       {errorArrayField.includes('comment') && (<div className={style.alert}>{'Обязательное поле не заполнено!'}</div>)}
                   </Label>
               </div>
               <div className={style.politika}>
                   <>{process.env.REACT_APP_LOGIN_PAGE_TEXT_COOKIE || 'Мы собираем файлы cookie в соответствии'} </>
                   <a href={process.env.REACT_APP_LOGIN_PAGE_URL || "/static_files/politika.pdf"} target='_blank'>
                       {process.env.REACT_APP_LOGIN_PAGE_URL_TEXT || "с политикой конфиденциальности."}</a>
               </div>
               <div className={style.buttonContainer}>
                   <Button className={style.button} size={'large'} type={'submit'} onClick={handleSubmitFriendInfo}>
                       Отправить
                   </Button>
               </div>
           </form>
           <InvisibleSmartCaptcha
               sitekey={process.env.REACT_APP_CAPTCHA_KEY || '123' }
               onSuccess={setToken}
               onChallengeHidden={handleChallengeHidden}
               visible={visible}
           />
       </>
    )
}

export default ConnectTvAndInternet;
