import axios from 'axios';
import { ConnectNewFriendAction, ConnectNewInternetAction } from "../../types/connectNewUser";

export const connectNewFriend = (params: ConnectNewFriendAction) => {
    return () => {
        return axios
            .post(`/api/site/send_info/${params.path}?token=${params.token}`, params.message)
    }
}

export const connectNewInternet = (params: ConnectNewInternetAction) => {
    return () => {
        return axios
            .post(`/api/site/send_info/${params.path}?token=${params.token}`, params.message)
    }
}

