import axios from './../../utils/axios';
import { Dispatch } from 'redux';
import { FetchContactsResponse, FetchCopyrightResponse, LayoutAction, LayoutActionTypes } from '../../types/layout';

export const fetchContacts =  () => {
    return async (dispatch: Dispatch<LayoutAction>) => {
        try  {
            dispatch({type: LayoutActionTypes.FETCH_CONTACTS})
            const response = await axios.get<FetchContactsResponse>('/api/site/get_contacts')
            dispatch({type: LayoutActionTypes.FETCH_CONTACTS_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({
                type: LayoutActionTypes.FETCH_CONTACTS_ERROR,
                payload: 'Ошибка при загрузке списка'
            })
        }
    }
}
export const fetchCopyright =  () => {
    return async (dispatch: Dispatch<LayoutAction>) => {
        try  {
            dispatch({type: LayoutActionTypes.FETCH_COPYRIGHT})
            const response = await axios.get<FetchCopyrightResponse>('/api/site/copyright')
            dispatch({type: LayoutActionTypes.FETCH_COPYRIGHT_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({
                type: LayoutActionTypes.FETCH_COPYRIGHT_ERROR,
                payload: 'Ошибка при загрузке списка'
            })
        }
    }
}

