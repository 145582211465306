const actionTypes = {
    LOGOUT: 'LOGOUT',
    STATUS_MESSAGE: 'STATUS_MESSAGE'
}

const initialState =  {
    status: false,
    message: '',

}
export const authReducer = (state = initialState, action) =>  {
    switch (action.type) {
        case actionTypes.STATUS_MESSAGE: {
            return {
                status: true,
                message: action.payload.message,
            }
        }
        case actionTypes.LOGOUT: {
            return {}
        }
        default: {
            return state;
        }
    }
}

export default authReducer;
