import React, {useEffect, useState} from 'react';

import ListTvInfo from './components/ListTvInfo/ListTvInfo';
import MainChannel from './components/MainChannel/MainChannel';
import BreadCrumbs from '../common/BreadCrumbs/BreadCrumbs';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks/useActions';
import { ReactComponent as Techno } from '../../static/images/techno.svg';

import style from './ListTv.module.css'


export default function ListTv () {

    const { tvInfo } = useActions()

    const { data } = useSelector(state => state.tvInfo);
    const [provider, setProvider] = useState('default');

    useEffect(() => {
        tvInfo(provider)
    }, [provider])

    if(!data.footer) {
        return
    }


    const handleSelectProviderId = (selectedId) => {
        setProvider(selectedId)
    }


    return (
        <div className={style.root}>
            <BreadCrumbs />
            <ListTvInfo />
            <MainChannel tvInfoSelectedId={handleSelectProviderId}  />
                    <div className={style.title}>{data.footer.info_string}</div>
                    <div className={style.subTitle}>{data.footer.platform}</div>
                    <div className={style.bannersContainer}>
                            <div className={style.brands}>
                                {data.footer.banners.map((item) => {
                                    return (
                                        <div key={item.id} >
                                            <a href={item.url}>
                                                <img className={style.image} src={item.icon} alt={item.id}/>
                                            </a>
                                        </div>
                                    )
                                })}
                                    <a href={data.footer.site.url} >
                                        <img className={style.image} src={data.footer.site.icon} alt={data.footer.site.id}/>
                                    </a>
                            </div>
                        <Techno className={style.imageTechno}/>
                    </div>
                <div className={style.footerText}>{data.footer.copyright}</div>
        </div>
    )
}
