import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks/useActions';

import Balance from './Balance/Balance';
import Statistics from './Statistics/Statistics';
import Tariff from './Tariff/Tariff';
import Feedback from './Feedback/Feedback';
import HeaderHome from './HeaderHome/HeaderHome';
import BreadCrumbs from '../common/BreadCrumbs/BreadCrumbs';
import Loading from '../../static/images/Loading.gif'


import style from './Home.module.css'


export default function Home() {

    const { baseInfo } = useActions();
    const loading = useSelector(state => state.baseInfo.loading);

    useEffect(() => {
        baseInfo();
    }, [])


    if (loading) {
        return (
           <div className={style.loading}>
               <img src={Loading} alt="Ожидайте"/>
           </div>
        )
    }

    return (
        <>
            <HeaderHome />
            <div className="container">
                <BreadCrumbs />
                <Feedback />
                <Balance />
                <Statistics />
                <Tariff />
            </div>
        </>
    );
}
