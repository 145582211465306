import axios from './../../utils/axios';
import { Dispatch } from 'redux';
import { FetchNewsResponse, NewsAction, NewsActionTypes } from '../../types/news';

export const fetchNews =  () => {
    return async (dispatch: Dispatch<NewsAction>) => {
        try  {
            dispatch({type: NewsActionTypes.FETCH_NEWS})
            const response = await axios.get<FetchNewsResponse>('/api/site/get_news')
            dispatch({type: NewsActionTypes.FETCH_NEWS_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({
                type: NewsActionTypes.FETCH_NEWS_ERROR,
                payload: 'Ошибка при загрузке списка'
            })
        }
    }
}
