import axios from '../../utils/axios';

const actionTypes = {
    PAYMENTS: 'PAYMENTS',
    PAYMENTS_SUCCESS: 'PAYMENTS_SUCCESS',
    PAYMENTS_ERROR: 'PAYMENTS_ERROR',
}

export const paymentStatistics = (params) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.PAYMENTS,
        })
        return axios
            .post('/api/lk/payments', {
                id_after: params.id_after ,
                per_page: params.per_page,
                filter: params.filter,
            })
            .then(res => {
                dispatch({
                    type: actionTypes.PAYMENTS_SUCCESS,
                    payload: {
                        status: res.data.status,
                        count: res.data.count,
                        payments: res.data.payments,
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.PAYMENTS_ERROR,
                    payload: {
                        message: error.payload.message
                    }
                })
            })
    }
}