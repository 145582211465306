import React from 'react';

import Channels from './components/Channels';
import News from './components/News';
import Tariffs from './components/Tariffs';
import Documents from './components/Documents';
import Contacts from './components/Contacts';
import Sales from './components/Sales/Sales';
import Title from "../common/Title";

import HeaderSite from './components/HeaderSite/HeaderSite';


const Site: React.FC = () => {

    return (
        <div>
            <HeaderSite />
            <div className="container">
                <Sales />
                <News />
                <Tariffs />
                <Channels />
                <Title>Акции</Title>
                <Sales />
                {/*<MoneyPoint />*/}
                <Documents />
                <Contacts />
            </div>
        </div>
    )
}

export default Site;
