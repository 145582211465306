import { MoneyPointState, MoneyPointAction, MoneyPointActionTypes} from "../../types/moneyPoint";

// @ts-ignore
const initialState: MoneyPointState = {
    terminals: [],
    paymentMethods: null,
    loading: false,
    error: null
}

export const moneyPointReducer = (state = initialState, action: MoneyPointAction): MoneyPointState => {
    switch (action.type){
        case MoneyPointActionTypes.FETCH_TERMINALS:
        case MoneyPointActionTypes.FETCH_PAYMENT_METHODS:
            return { ...state, loading: true}
        case MoneyPointActionTypes.FETCH_TERMINALS_SUCCESS:
            return {...state, loading: false, terminals: action.payload}
        case MoneyPointActionTypes.FETCH_PAYMENT_METHODS_SUCCESS:
            return { ...state, loading: false, paymentMethods: action.payload}
        case MoneyPointActionTypes.FETCH_TERMINALS_ERROR:
        case MoneyPointActionTypes.FETCH_PAYMENT_METHODS_ERROR:
            return { ...state, loading: false, error: action.payload}
        default:
            return state;
    }
}
