
export interface OneNewsState {
    data: FetchOneNewsResponse,
    loading: boolean;
    error: null | string;
}

export interface FetchOneNewsResponse {
    id: number,
    text: string,
    title: string,
    icon_url: string,
    date: string
}


export enum OneNewsActionTypes  {
    ONE_NEWS = 'ONE_NEWS',
    ONE_NEWS_SUCCESS = 'ONE_NEWS_SUCCESS',
    ONE_NEWS_ERROR = 'ONE_NEWS_ERROR',
}

interface FetchOneNewsAction {
    type: OneNewsActionTypes.ONE_NEWS
}

interface FetchOneNewsSuccessAction {
    type: OneNewsActionTypes.ONE_NEWS_SUCCESS,
    payload: FetchOneNewsResponse

}

interface FetchOneNewsErrorAction {
    type: OneNewsActionTypes.ONE_NEWS_ERROR,
    payload: string
}

export type OneNewsAction = FetchOneNewsAction | FetchOneNewsSuccessAction | FetchOneNewsErrorAction;
