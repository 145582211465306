const actionTypes = {
    PAYMENTS: 'PAYMENTS',
    PAYMENTS_SUCCESS: 'PAYMENTS_SUCCESS',
    PAYMENTS_ERROR: 'PAYMENTS_ERROR',
}

const initialState = {
    info: {
        status: false,
        count: 0,
        payments: null,
    },
    loading: false,
    error: null,
}

export const paymentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PAYMENTS: {
            return {
                ...state,
                loading: true
            }
        }
        case  actionTypes.PAYMENTS_SUCCESS: {
            return  {
                ...state,
                info: action.payload,
                loading: false,
            }

        }
        case actionTypes.PAYMENTS_ERROR: {
            return  {
                ...state,
                error: action.payload.message,
                loading: false
            }
        }
        default: {
            return state;
        }
    }
}

export default paymentsReducer;
