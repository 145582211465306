export interface MoneyPointState {
    terminals: FetchTerminalsResponse [];
    paymentMethods: FetchPaymentMethodsResponse | null;
    loading: boolean;
    error: null | string;
}

export interface FetchTerminalsResponse {
    coordinates: ICoordinates,
    count: number
}

export type ICoordinates = {
    latitude: string;
    longitude: string;

}
export interface FetchPaymentMethodsResponse {
    count: number,
    payment_method: IPaymentMethod [],
}

export type IPaymentMethod  = {
    name: string,
    params: string,
    type: string,
}

export enum MoneyPointActionTypes {
    FETCH_TERMINALS = 'FETCH_TERMINALS',
    FETCH_TERMINALS_SUCCESS  = 'FETCH_TERMINALS_SUCCESS',
    FETCH_TERMINALS_ERROR = 'FETCH_TERMINALS_ERROR',
    FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS',
    FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS',
    FETCH_PAYMENT_METHODS_ERROR = 'FETCH_PAYMENT_METHODS_ERROR',
}

interface FetchTerminalsAction {
    type: MoneyPointActionTypes.FETCH_TERMINALS
}

interface FetchTerminalsSuccessAction {
    type: MoneyPointActionTypes.FETCH_TERMINALS_SUCCESS
    payload: FetchTerminalsResponse []
}

interface FetchTerminalsErrorAction {
    type: MoneyPointActionTypes.FETCH_TERMINALS_ERROR
    payload: string
}
interface FetchPaymentMethodsAction {
    type: MoneyPointActionTypes.FETCH_PAYMENT_METHODS
}

interface FetchPaymentMethodsSuccessAction {
    type: MoneyPointActionTypes.FETCH_PAYMENT_METHODS_SUCCESS
    payload: FetchPaymentMethodsResponse
}

interface FetchPaymentMethodsErrorAction {
    type: MoneyPointActionTypes.FETCH_PAYMENT_METHODS_ERROR
    payload: string
}


export type MoneyPointAction = FetchTerminalsAction | FetchTerminalsSuccessAction | FetchTerminalsErrorAction
    | FetchPaymentMethodsAction | FetchPaymentMethodsSuccessAction | FetchPaymentMethodsErrorAction;
