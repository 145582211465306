export interface NewsState {
    data: FetchNewsResponse;
    loading: boolean;
    error: null | string;
}

export interface FetchNewsResponse {
    count: number;
    news: INews []
}
export interface INews {
    id: number,
    text: string,
    title: string,
    icon_url: string,
    date: string,
    showModal: boolean,
}

export enum NewsActionTypes {
    FETCH_NEWS = 'FETCH_NEWS',
    FETCH_NEWS_SUCCESS  = 'FETCH_NEWS_SUCCESS',
    FETCH_NEWS_ERROR = 'FETCH_NEWS_ERROR',
}

interface FetchNewsAction {
    type: NewsActionTypes.FETCH_NEWS
}

interface FetchNewsSuccessAction {
    type: NewsActionTypes.FETCH_NEWS_SUCCESS
    payload: FetchNewsResponse
}

interface FetchNewsErrorAction {
    type: NewsActionTypes.FETCH_NEWS_ERROR
    payload: string
}


export type NewsAction = FetchNewsAction | FetchNewsSuccessAction | FetchNewsErrorAction;
