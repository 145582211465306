import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../../hooks/useActions';

import Button from '../../../../common/Button/Button';
import Label from '../../../../common/Label/Label';
import Input from '../../../../common/Input';

import style from './Payment.module.css';



export default  function Payment (){
    const { hideWindow, getPaymentsTypes, getPaymentInfo } = useActions();

    const { paymentMethod, recommendedAmount} = useSelector(state => state.paymentsCreate.paymentTypes);
    const typeId = useSelector(state => state.paymentsCreate.id);
    const [selectedId, setSelectedId] = useState('')
    const [paymentSum, setPaymentSum] = useState(null);
    const {  qr, mode, error, url} = useSelector(state => state.paymentsCreate.paymentsInfo);

    useEffect(() => {
        getPaymentsTypes()
    }, [])

    useEffect(() => {
        setSelectedId(typeId)

    }, [typeId])

    const paymentHandleSubmit =  () => {
        let amount = paymentSum;
        if (amount === null) {
            amount = recommendedAmount;
        }

        getPaymentInfo({
            selectedId,
            amount
        }).then(response => {
            if (response.data.mode === 'redirect') {
                window.location.assign(response.data.url)
                hideWindow()
            }
        })
    }

    const onChangePayment = (paymentSum) => {
        setPaymentSum(Number(paymentSum))
    }

    const changeTypePayment = (event, name, selected) => {
        setSelectedId(selected)
    }

    if (!paymentMethod) {
        return
    }

    return (
        <div className={style.root}>
            {paymentMethod.map(({id, name, comment }) => {
                return (
                    <div className={style.metodContainer} key={id}>
                        <Input
                            type={'radio'}
                            radioSize={true}
                            onChange={changeTypePayment}
                            checked={selectedId === id}
                            selectedId={id}
                            name={name}
                        />
                        <div>
                            <div className={style.name}>{name}</div>
                            <div className={style.comment}>{comment}</div>
                        </div>
                    </div>
                )
            })}
            <Label title={'Сумма платежа'} size='medium'>
                <div className={style.mainPayment} >
                    <div className={style.inputContainer}>
                        <Input
                            onChange={onChangePayment}
                            field='selectBalance'
                            type={'number'}
                            placeholder={`Рекомендованный платеж: ${recommendedAmount}`}
                        />
                    </div>
                    <div className={style.rub}>руб.</div>
                </div>
            </Label>
            <>
                {mode === 'qr' &&(
             <div className={style.qrContainer}>
                 <div  className={style.base64Png}>
                     <img  src= {`data:image/png;base64,${qr}`} alt="QR-CODE"/>
                 </div>
                 <a className={style.qrInfoText} href={url}>Перейти на сайт НСПК (можно выбрать банк при оплате с телефона</a>
             </div>
                )}

                {mode === 'error' &&(
                    <div>{error}</div>
                )}
            </>
            <div className={style.buttonContainer}>
                <Button className={style.mainButton} size="large" shadow  onClick={paymentHandleSubmit}>
                    Оплатить
                </Button>
            </div>
        </div>
    );
}
