import React from 'react';

import { getClassName } from '../../../utils/className';

import style from './Button.module.css'

type IButton =  {
    children: React.ReactNode;
    type?: "button" | "submit" | "reset";
    theme?: 'primary' | 'secondary';
    size?: 'small' | 'large';
    shadow?: boolean;
    authSize?:boolean;
    className?: string;
    activeButtonWhite?: boolean;
    activeButtonRed?: boolean;
    selectedButton?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>, id?: string) => void;
    id?: string;
}


const Button: React.FC<IButton> = (
    {
        children,
        type = 'button' ,
        theme = 'primary',
        size = 'small',
        shadow = false,
        authSize = false,
        className,
        activeButtonWhite,
        activeButtonRed,
        selectedButton,
        onClick,
        id
    }) => {


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(event)
        }
    }


    const cls = getClassName({
        [style.root]: true,
        [style[theme]]: true,
        [style[size]]: true,
        [style.shadow]: shadow,
        [style.auth]: authSize,
        [style.disabledButtonWhite]: activeButtonWhite,
        [style.disabledButtonRed]: activeButtonRed,
        [style.selectedButton]: selectedButton
    });

    return (
        <button type={type} onClick={handleClick} className={`${className} ${cls}`}>
            { children }
        </button>
    )
}

export default Button;
