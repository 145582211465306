const actionTypes = {
    WHITE_IP: 'WHITE_IP',
    WHITE_IP_SUCCESS: 'WHITE_IP_SUCCESS',
    WHITE_IP_ERROR: 'WHITE_IP_ERROR'
}

 const initialState = {
  info: {
      count: 0,
      text: null,
      ip_address: null,
      min_disable_dt: '',
      status: false,
      white_ip: false,
      white_ip_allow_disable: false,
  },
    loading: false,
    error: null
}

export const whiteIpReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.WHITE_IP: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.WHITE_IP_SUCCESS: {
            return {
                ...state,
                info: action.payload,
                loading: false
            }
        }
        case actionTypes.WHITE_IP_ERROR: {
            return {
                ...state,
                error: action.payload.message,
                loading: false
            }
        }
        default: {
            return state
        }
    }
}

export default whiteIpReducer;