import React from 'react';

import style from './Select.module.css'

type ISelect = {
    option: IOption[];
    onChange: (eventValue: string | number) => void;
}
interface IOption {
    key: string;
    title: string;
}

const Select: React.FC<ISelect> = ( { option, onChange } ) => {
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const eventValue = event.target.value;
        onChange(eventValue)
    }

    return (
            <select
                name="selectedPayment"
                onChange={handleChange}
                className={style.root}
            >
                {option.map(({key, title}) => {
                    return (
                        <option value={key} key={key}>{title}</option>
                    )
                })}
            </select>
    )
}

export default Select
