const actionTypes = {
    PAYMENT_CREATE: 'PAYMENT_CREATE',
    PAYMENT_CREATE_SUCCESS: 'PAYMENT_CREATE_SUCCESS',
    PAYMENT_CREATE_ERROR: 'PAYMENT_CREATE_ERROR',
    PAYMENT_CREATE_POST: 'PAYMENT_CREATE_POST',
    PAYMENT_CREATE_POST_SUCCESS: 'PAYMENT_CREATE_POST_SUCCESS',
    PAYMENT_CREATE_POST_ERROR: 'PAYMENT_CREATE_POST_ERROR',
}

const initialState = {
    paymentTypes: {
        recommendedAmount: 0,
        paymentMethod: null,
        minPayAmount: 0,
    },
    paymentsInfo: {
        url: '',
        qr: '',
        qr_id: '',
        mode: '',
        error: '',
    },
    id: null,
    loading: false,
    error: null,
}

export const paymentCreate = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PAYMENT_CREATE: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.PAYMENT_CREATE_SUCCESS: {
            return {
                ...state,
                paymentTypes: action.payload,
                id: action.payload.id,
                loading: false
            }
        }
        case actionTypes.PAYMENT_CREATE_ERROR: {
            return {
                ...state,
                error: action.payload.message,
                loading: false
            }
        }
        case actionTypes.PAYMENT_CREATE_POST_SUCCESS: {
            return {
                ...state,
                paymentsInfo: action.payload,
                loading: false
            }
        }
        default: {
            return state
        }
    }
}

export default paymentCreate;