import React  from 'react';

import styles from './Card.module.css'

type CardProps = {
    children?: React.ReactNode | undefined;
}
const Card: React.FC<CardProps> = (props) => (
    <div className={styles.root}>
        {props.children}
    </div>
)
export default Card
