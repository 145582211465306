import { ChannelsState, ChannelsAction, ChannelsActionTypes} from '../../types/channels';

// @ts-ignore
const initialState: ChannelsState = {
    packages: {
        count: 0,
        packets: []
    },
    package: null,
    loading: false,
    error: null
}

export const channelsReducer = (state = initialState, action: ChannelsAction): ChannelsState => {
    switch (action.type){
        case ChannelsActionTypes.FETCH_TV:
        case ChannelsActionTypes.FETCH_PACKAGE:
            return { ...state, loading: true}
        case ChannelsActionTypes.FETCH_TV_SUCCESS:
            return { ...state, loading: false, packages: action.payload}
        case ChannelsActionTypes.FETCH_PACKAGE_SUCCESS:
            return { ...state, loading: false, package: action.payload}
        case ChannelsActionTypes.FETCH_TV_ERROR:
        case ChannelsActionTypes.FETCH_PACKAGE_ERROR:
            return { ...state, loading: false, error: action.payload}
        default:
            return state;
    }
}
