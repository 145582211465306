import { TariffsState, TariffsAction, TariffsActionTypes} from "../../types/tariffs";

// @ts-ignore
const initialState: TariffsState = {
    data: {
        count: 0,
        tariffs: []
    },
    loading: false,
    error: null
}

export const tariffsReducer = (state = initialState, action: TariffsAction): TariffsState => {
    switch (action.type){
        case TariffsActionTypes.FETCH_TARIFFS:
            return { ...state, loading: true}
        case TariffsActionTypes.FETCH_TARIFFS_SUCCESS:
            return { ...state, loading: false, data: action.payload}
        case TariffsActionTypes.FETCH_TARIFFS_ERROR:
            return { ...state, loading: false, error: action.payload}
        default:
            return state;
    }
}
