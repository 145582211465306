import { createStore, applyMiddleware, compose  } from 'redux'
import { combineReducers,  } from 'redux';
import thunk from 'redux-thunk'

import authReducer from './reducers/authReducer';
import baseInfoReducer from './reducers/baseInfoReducer';
import tariffReducer from './reducers/tariffReducer';
import promisedPayReducer from './reducers/promisedPayReducer';
import reservationReducer from './reducers/reservationReducer';
import personalInfoReducer from './reducers/personalInfoReducer';
import whiteIpReducer from './reducers/whiteIpReducer';
import allTvPacketReducer from './reducers/allTvPacketReducer';
import paymentsReducer from './reducers/paymentsStatisticsReducer';
import paymentsCreateReducer from './reducers/paymentsCreateReducer';
import tvProvidersReducer from './reducers/tvProvidersReducer';

//ts
import { layoutReducer } from './reducers/layoutReducer';
import { newsReducer } from './reducers/newsReducer';
import { tariffsReducer } from './reducers/tariffsReducer';
import { channelsReducer } from './reducers/channelsReducer';
import { documentsReducer } from './reducers/documentsReducer';
import { moneyPointReducer } from './reducers/moneyPointReducer';
import { windowReducer } from './reducers/windowReducer';
import { listChannelsReducer } from './reducers/listChaneelsForIdReducer';
import { oneNewsReducer } from './reducers/oneNewsReducer';
import tvInfoReducer from './reducers/tvProviderInfoReducer';



export const rootReducer = combineReducers({
    auth: authReducer,
    baseInfo: baseInfoReducer,
    tariff: tariffReducer,
    promisedPay: promisedPayReducer,
    reservationInfo: reservationReducer,
    personalInfo: personalInfoReducer,
    whiteIp: whiteIpReducer,
    allTvPackets: allTvPacketReducer,
    payments: paymentsReducer,
    paymentsCreate: paymentsCreateReducer,
    listChannels: listChannelsReducer,
    tvProviders: tvProvidersReducer,
    tvInfo: tvInfoReducer,

    //ts
    layout: layoutReducer,
    news: newsReducer,
    oneNews: oneNewsReducer,
    tariffs: tariffsReducer,
    channels: channelsReducer,
    documents: documentsReducer,
    moneyPoint: moneyPointReducer,
    window: windowReducer
});

const middlewares = [
    applyMiddleware(thunk)
];

if (process.env.NODE_ENV === 'development'){
    middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__&& window.__REDUX_DEVTOOLS_EXTENSION__())
}
export default createStore(rootReducer, compose(...middlewares))
