const actionTypes = {
    PERSONAL_INFO: 'PERSONAL_INFO',
    PERSONAL_INFO_SUCCESS: 'PERSONAL_INFO_SUCCESS',
    PERSONAL_INFO_ERROR: 'PERSONAL_INFO_ERROR',
    PERSONAL_INFO_POST: 'PERSONAL_INFORMATION_POST',
}

export const initialState = {
    info: {
        phone: '',
        phone2: '',
        email: '',
        name: '',
        surname: '',
        middlename: '',
        address: '',
        TgToken: '',
        tgInfo: null,
        emailInfo: null,
        TgBotUrl: ''
    },
    loading: false,
    error: null
}


export const personalInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PERSONAL_INFO: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.PERSONAL_INFO_SUCCESS: {
            return {
                ...state,
                info: action.payload,
                loading: false
            }
        }
        case actionTypes.PERSONAL_INFO_ERROR: {
            return {
                ...state,
                error: action.payload.message
            }
        }
        // case actionTypes.PERSONAL_INFO_POST: {
        //     console.log(initialState.info)
        //     return {
        //         ...state,
        //         info: action.payload,
        //         loading: false
        //     }
        // }
        default: {
            return state
        }
    }
}

export default personalInfoReducer;