import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../../hooks/useActions';

import Button from '../../../../common/Button/Button';
import CheckBox from '../../../../common/CheckBox/CheckBox';

import style from './WhiteIp.module.css'


export default function WhiteIp () {

    const { hideWindow, getWhiteIpInfo, whiteIpEnable, whiteIpDisabled } = useActions();
    const text  = useSelector(state => state.whiteIp.info.text);
    const { ip_address, min_disable_dt, white_ip_allow_disable, white_ip }  = useSelector(state => state.whiteIp.info);
    const [checked, setChecked] = useState(null)

    useEffect(() => {
        getWhiteIpInfo()
    }, [])

    useEffect(() => {
        setChecked(white_ip)
    }, [white_ip])

    if (!text) {
        return;
    }

    const enableAndDisabledWhiteIp = () => {
        checked? whiteIpEnable() : whiteIpDisabled()
        hideWindow()
    }


    return (
        <div className={style.root}>
            <div dangerouslySetInnerHTML={{__html:text}} className={style.content}></div>
            <div className={style.switchContainer}>
                <div className={style.ipAddress}>Ваш IP: {ip_address} </div>
                <CheckBox
                    text={'Активировать услугу'}
                    onChange={() => setChecked(!checked)}
                    checked={checked}
                    disabled={!white_ip_allow_disable}
                    textColor={true}
                    id={'whiteIp'}
                />
            </div>
            {!white_ip_allow_disable && (
                <div className={style.ipDateDisabled}> Отключить после: {min_disable_dt}</div>
            )}
            <div className={style.buttonContainer}>
                <Button className={style.mainButton} size="large" shadow onClick={enableAndDisabledWhiteIp}>
                    Применить
                </Button>
            </div>
        </div>
    )
}
