import React, { useState } from 'react';

import { ReactComponent as Icon } from '../../../static/images/Icon Contained.svg';

import style from './DropDown.module.css'

interface IDropDown {
    title: string;
    children: React.ReactNode;
    hideBottomLine?: boolean;
    openDropDown?: boolean;
}

const DropDown: React.FC<IDropDown> = (
    {
        title,
        children,
        hideBottomLine = false,
        openDropDown = false
    }) => {

    const [open, setOpen] = useState(openDropDown);

    return (
        <>
            <div className={style.line}></div>
            <div className={style.headerContainer}>
                <div className={style.title}>{title}</div>
                <Icon className={open ? style.active : style.inactive}
                      onClick={() => setOpen(!open)}/>
            </div>
            { open  &&(
                <>
                    {children}
                </>
            )}

            {!hideBottomLine && (
                <div className={style.line}></div>
            )}
        </>
    )
}

export default DropDown
