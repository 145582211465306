import React from 'react';
import { useRouteError } from 'react-router-dom'

import style from './ErrorPage.module.css';

const ErrorPage = () => {
    const error = useRouteError()
    console.error(error);

    return (
        <div className={style.error}>404</div>
    )
}

export default ErrorPage
