import axios from './../../utils/axios';
import { Dispatch } from 'redux';
import { FetchTariffsResponse, TariffsAction, TariffsActionTypes } from '../../types/tariffs';

export const fetchTariffs =  () => {
    return async (dispatch: Dispatch<TariffsAction>) => {
        try  {
            dispatch({type: TariffsActionTypes.FETCH_TARIFFS})
            const response = await axios.get<FetchTariffsResponse>('/api/site/get_tariffs')
            dispatch({type: TariffsActionTypes.FETCH_TARIFFS_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({
                type: TariffsActionTypes.FETCH_TARIFFS_ERROR,
                payload: 'Ошибка при загрузке списка'
            })
        }
    }
}
