import React from 'react';
import { useActions } from '../../../../hooks/useActions';

import { hideWindow } from '../../../../store/actions/windowActions';
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import Window from '../../../common/Window';


const WindowStore = () => {
    const { show, content, title, showCloseButton, header, scrollBlock } = useTypedSelector((state) => state.window);

    const { hideWindow } = useActions();

    const handleCloseButton = () => {
        hideWindow()
    }

    return (
        <Window
            show={show}
            content={content}
            title={title}
            showCloseButton={showCloseButton}
            handleCloseButton={handleCloseButton}
            header={header}
            scrollBlock={scrollBlock}
        />
    );
}

export default WindowStore;
