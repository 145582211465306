import axios from './../../utils/axios';
import { Dispatch } from 'redux';
import { FetchDocumentsResponse, DocumentsAction, DocumentsActionTypes } from '../../types/documents';

export const fetchDocuments =  () => {
    return async (dispatch: Dispatch<DocumentsAction>) => {
        try  {
            dispatch({type: DocumentsActionTypes.FETCH_DOCUMENTS})
            const response = await axios.get<FetchDocumentsResponse>('/api/site/get_documents');
            dispatch({type: DocumentsActionTypes.FETCH_DOCUMENTS_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({
                type: DocumentsActionTypes.FETCH_DOCUMENTS_ERROR,
                payload: 'Ошибка при загрузке списка'
            })
        }
    }
}
