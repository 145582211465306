const actionTypes = {
    PROMISED_PAY: 'PROMISED_PAY',
    PROMISED_PAY_SUCCESS: 'PROMISED_PAY_SUCCESS',
    PROMISED_PAY_ERROR: 'PROMISED_PAY_ERROR',
    CONFIRMED_PAY: 'CONFIRMED_PAY',
    CONFIRMED_PAY_ERROR: 'CONFIRMED_PAY_ERROR',
}

const initialState = {
    info: {
        allow: null,
        count: 0,
        text: null,
        message: ''
    },
    loading: false,
    error: null,
    status: false
}

export const promisedPayReducer = (state = initialState, action) => {
    switch (action.type){
        case actionTypes.PROMISED_PAY: {
            return {
                ...state,
                loading: true
            }
        }
        case actionTypes.PROMISED_PAY_SUCCESS: {
            return {
                ...state,
                info: action.payload,
                loading: false
            }
        }
        case actionTypes.PROMISED_PAY_ERROR: {
            return {
                ...state,
                error: action.payload.message,
                loading: false
            }
        }
        case actionTypes.CONFIRMED_PAY: {
            return {
                ...state,
                status: true
            }
        }
        default: {
            return state;
        }
    }
}

export default promisedPayReducer;