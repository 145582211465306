import React  from 'react';

import styles from './Title.module.css'

type TitleProps = {
    children?: React.ReactNode
}
const Title: React.FC<TitleProps> = (props) => (
    <div className={styles.root}>
        {props.children}
    </div>
)
export default Title
