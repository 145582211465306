import axios from '../../utils/axios';

const actionTypes = {
    LIST_CHANNELS: 'LIST_CHANNELS',
    LIST_CHANNELS_SUCCESS: 'LIST_CHANNELS_SUCCESS',
    LIST_CHANNELS_ERROR: 'LIST_CHANNELS_ERROR',
}

export const ListTvInfo = (params) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.LIST_CHANNELS
        })
        return axios.get(`/api/tv/get_${params.provider}_channels/${params.id}`)
            .then(response => {
                dispatch({
                    type: actionTypes.LIST_CHANNELS_SUCCESS,
                    payload: {
                        channels: response.data.channels,
                        count: response.data.count,
                        name: response.data.name,
                        price: response.data.price,
                        priceType: response.data.price_type,
                        short: response.data.short,
                    }
                });

                return response;
            });
    }
}