import axios from '../../utils/axios';

const actionTypes = {
    WHITE_IP: 'WHITE_IP',
    WHITE_IP_SUCCESS: 'WHITE_IP_SUCCESS',
    WHITE_IP_ERROR: 'WHITE_IP_ERROR'
}

export const getWhiteIpInfo = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.WHITE_IP,
        })
        axios
            .get('/api/lk/white_ip')
            .then(res => {
                dispatch({
                    type: actionTypes.WHITE_IP_SUCCESS,
                    payload: {
                        ip_address: res.data.ip_address,
                        min_disable_dt: res.data.min_disable_dt,
                        status: res.data.status,
                        white_ip: res.data.white_ip,
                        white_ip_allow_disable: res.data.white_ip_allow_disable,
                        count: res.data.text.count,
                        text: res.data.text.text
                    }
                })
                return res
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.WHITE_IP_ERROR,
                    payload: {
                        message: error.payload.message
                    }
                })
            })
    }
}

export const  whiteIpEnable = () => {
    return () => {
        return axios.post('/api/lk/white_ip')
    }
}

export const  whiteIpDisabled = () => {
    return () => {
        return axios.delete('/api/lk/white_ip')
    }
}
