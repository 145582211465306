import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useActions } from '../../../hooks/useActions';

import Reserve from './component/Reserve/Reserve';
import Button from '../../common/Button/Button';
import TariffPlane from './component/TariffPlane/TariffPlane';

import style from './Tariff.module.css'



export default function Tariff() {
    const { showWindow } = useActions();

    const { reservation_allow } = useSelector(state => state.baseInfo.info)
    const showWindowReserve = () => {
        const content = <Reserve/>
        showWindow({
            content,
            title: 'Добровольная блокировка абонентской линии',
            showCloseButton: true,
            header: true,
        })
    }

    return (
        <div className={style.mainTariff}>
            <TariffPlane />
            <div className={style.buttonContainer}>
                <Button
                    className={style.buttonSecondary}
                    onClick={showWindowReserve}
                    theme={'secondary'}
                    activeButton={!reservation_allow}
                    size={'large'}
                    >
                    Резервирование
                </Button>
                <Link to='list-tv'>
                    <Button size={'large'} className={style.buttonPrimary} >
                        Телевидение
                    </Button>
                </Link>
            </div>
        </div>
    );
}
