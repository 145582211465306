import { NewsState, NewsAction, NewsActionTypes} from "../../types/news";

const initialState: NewsState = {
    data: {
        count: 0,
        news: []
    },
    loading: false,
    error: null
}

export const newsReducer = (state = initialState, action: NewsAction): NewsState => {
    switch (action.type){
        case NewsActionTypes.FETCH_NEWS:
            return { ...state, loading: true}
        case NewsActionTypes.FETCH_NEWS_SUCCESS:
            return { ...state, loading: false, data: action.payload}
        case NewsActionTypes.FETCH_NEWS_ERROR:
            return { ...state, loading: false, error: action.payload}
        default:
            return state;
    }
}
