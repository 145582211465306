import React from 'react';

import style from './PaymentSuccess.module.css'
export default function PaymentSuccess ()  {
    setTimeout(function(){
        location.href = '/lk';
    }, 10000);

    return (
        <div className={style.root}>
            <div className={style.test}>Ваша заявка принята!<br/>
                После одобрения банком деньги поступят в течении нескольких минут.
            </div>
        </div>
    )
}