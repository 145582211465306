import React, { useEffect } from "react";

import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import Title from "../../../common/Title";
import TariffItem from './components/TariffItem';

import styles from './Tariffs.module.css'


const Tariffs: React.FC = () => {
    const { fetchTariffs } = useActions();
    const { data } = useTypedSelector(state => state.tariffs)


    useEffect(()=> {
        fetchTariffs()
    },[])

    const mainTextEnv = process.env.REACT_APP_BANDL1_MAIN_TARIFF
    const secondTextEnv = process.env.REACT_APP_BANDL1_SECOND_TARIFF

    return (
        <>
            <Title>Интернет</Title>
            <div className={styles.textContainer}>
                <div className={styles.mainText}>{mainTextEnv || 'Мы можем предоставить вам бесплатное подключение к пакету каналов "Бандл1" - 112 каналов'}</div>
                <div className={styles.secondText}>{secondTextEnv || '(количество может быть изменено сервисом, предоставляемым ООО "24часаТВ")'}</div>
            </div>
            <div className={styles.container}>
                {data.tariffs && data.tariffs.map(({id, name, price, price_type, speed}) => {
                    return (
                        <TariffItem
                            key={id}
                            id={id}
                            name={name}
                            price={price}
                            price_type={price_type}
                            speed={speed}
                        />
                    )
                })}
            </div>
        </>
    );
}
export default Tariffs
