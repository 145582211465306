import React, { ReactNode } from 'react';

import style from './IconButton.module.css'
import {getClassName} from "../../../utils/className";

interface IIcon {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    round: boolean;
    children: ReactNode;
    theme: string;
}

const IconButton: React.FC<IIcon> = (
    {
        onClick,
        theme = 'primary',
        round = true,
        children
    }) => {

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(event)
        }
    }

    const csl = getClassName({
        [style.root]: true,
        [style[theme]]: theme,
        [style.round]: round
    })

    return  (
        <button className={csl} onClick={handleClick}>
            {children}
        </button>
    )
}

export default IconButton;
