import React from 'react';

import AuthInfo from './AuthInfo/AuthInfo';
import AuthForm from './AuthForm/AuthForm';


export default function Auth () {
    return (
        <>
            <AuthInfo />
            <AuthForm />
        </>
    )
}
