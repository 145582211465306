import React from "react";
import { createBrowserRouter, Link } from 'react-router-dom';

import Auth from './components/Auth/Auth';

import ListTv from './components/ListTv/ListTv';
import Home from './components/Home/Home';
import PersonalInformation from './components/PersonalInformation/PersonalInformation';
import PaymentSuccess from './components/Home/Balance/component/Payment/componets/PaymentSuccess/PaymentSuccess';
import PaymentError from './components/Home/Balance/component/Payment/componets/PaymentError/PaymentError';
import ErrorPage from './components/ErrorPage/ErrorPage';

import Site from './components/Site/Site';
import News from './components/Site/components/News';
import Channels from './components/Site/components/Channels';
import Tariffs from './components/Site/components/Tariffs';
import Sales from './components/Site/components/Sales';
import Documents from './components/Site/components/Documents';
import Contacts from './components/Site/components/Contacts';
import HeaderSite from './components/Site/components/HeaderSite/HeaderSite';
import HeaderHome from './components/Home/HeaderHome/HeaderHome';

import style from './router.module.css'


const router =  createBrowserRouter([
    {
        path: "/",
        children: [
            {
                index: true,
                element: <Site/>
            },
            {
                path: 'news',
                element: <HeaderSite><News /></HeaderSite>
            },
            {
                path: 'tariffs',
                element: <HeaderSite><Tariffs /></HeaderSite>
            },
            {
                path: 'channels',
                element: <HeaderSite><Channels /></HeaderSite>
            },
            {
                path: 'sales',
                element: <HeaderSite><Sales /></HeaderSite>
            },
            {
                path: 'document',
                element: <HeaderSite><Documents /></HeaderSite>
            },
            {
                path: 'contact',
                element: <HeaderSite><Contacts /></HeaderSite>
            },
        ],
        errorElement: <ErrorPage />,
    },
    {
        path: "lk",
        handle: {
            crumb: () => <Link to={'/lk'} className={style.root}>Личный кабинет</Link>
        },
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: "list-tv",
                element: <HeaderHome><ListTv /></HeaderHome>,
                handle: {
                    crumb: () => <Link to={'/lk/list-tv'} className={style.root}>&nbsp;→&nbsp;Телевидение</Link>,
                }
            },
            {
                path: "personal-info",
                element: <HeaderHome><PersonalInformation /></HeaderHome>,
                handle: {
                    crumb: () => <Link to={'/lk/personal-info'} className={style.root}>&nbsp;→&nbsp;Персональная информация</Link>,
                }
            },
            {
                path: "payments-success",
                element: <PaymentSuccess />,

            },
            {
                path: "payments-error",
                element: <PaymentError />,

            },
        ]
    },
    {
        path: "login",
        element:  <HeaderHome><Auth /></HeaderHome>,
    },
]);

export default router;
