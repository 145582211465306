import { ReactNode } from "react";

export interface WindowState {
    show: boolean,
    content: ReactNode | undefined;
    title: string,
    showCloseButton: boolean,
    header: boolean,
    scrollBlock: boolean,
}
export enum WindowActionTypes {
    SHOW_WINDOW = 'SHOW_WINDOW',
    HIDE_WINDOW = 'HIDE_WINDOW'
}

export interface IWindowParams {
    title: string;
    showCloseButton: boolean;
    header: boolean;
    content: ReactNode | undefined;
    scrollBlock: boolean;
}

export interface WindowActionShow {
    type: WindowActionTypes.SHOW_WINDOW
    payload: WindowState
}
export interface WindowActionHide {
    type: WindowActionTypes.HIDE_WINDOW
}

export type WindowAction = WindowActionShow | WindowActionHide;