import { LayoutState, LayoutAction, LayoutActionTypes} from "../../types/layout";

// @ts-ignore
const initialState: LayoutState = {
    contacts: {
        address: '',
        email: '',
        url: '',
        coordinates: {
            lat: 0,
            lon: 0
        },
        phones: [],
        messenger: [],
        ur_info: {
            inn: 0,
            kpp: 0,
            name: '',
            ogrn: 0
        },
    },
    copyright: {
        copyright: ''
    },
    loading: false,
    error: null
}

export const layoutReducer = (state = initialState, action: LayoutAction): LayoutState => {
    switch (action.type){
        case LayoutActionTypes.FETCH_CONTACTS:
        case LayoutActionTypes.FETCH_COPYRIGHT:
            return { ...state, loading: true}
        case LayoutActionTypes.FETCH_CONTACTS_SUCCESS:
            return { ...state, loading: false, contacts: action.payload}
        case LayoutActionTypes.FETCH_COPYRIGHT_SUCCESS:
            return { ...state, loading: false, copyright: action.payload}
        case LayoutActionTypes.FETCH_CONTACTS_ERROR:
        case LayoutActionTypes.FETCH_COPYRIGHT_ERROR:
            return { ...state, loading: false, error: action.payload}
        default:
            return state;
    }
}
