import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../hooks/useActions';

import DropDown from '../../common/DropDown/DropDown';
import  { paymentStatistics }  from '../../../store/actions/paymentsStatisticsAction';
import { ReactComponent as Minus } from '../../../static/images/minus.svg';
import { ReactComponent as Plus } from '../../../static/images/plus.svg';
import Select from '../../common/Select/Select';
import { isAdaptive } from '../../../hoc/isAdaptive';

import style from './Statistics.module.css'


function  Statistics ({ viewType }) {

    const { paymentStatistics } = useActions();

    const { payments } = useSelector(state => state.payments.info);

    useEffect(() => {
        paymentStatistics({
            id_after: 0,
            filter: 'all'
        })
    }, [])

    if (!payments) {
        return
    }
    const options = [
        {
            key: 'all',
            title: 'Все платежи'
        },
        {
            key: 'popolnenie',
            title: 'Пополнение'
        },
        {
            key: 'spisanie',
            title: 'Списание'
        }
    ]

    const navigateData = ['Дата', 'Операция','Сумма','Баланс','Примечание']

    const handleChangeSelected = (optionValue) => {
        paymentStatistics({
            id_after: 0,
            filter: optionValue
        })
    }


    return (
        <DropDown title='Статистика платежей'>
            <Select option={options} onChange={handleChangeSelected}/>
            <div className={style.mainContainer}>
                {navigateData.map((item, index) => <div key={index}>{item}</div>)}
            </div>
            {payments.map(({date, day, time, pay_sum, currency, comment, balance, type, id, CheckLink} ) => {
                return (
                    <div className={style.mainContainer} key={id}>

                        {viewType === 'mobile' && (
                            <div className={style.mobileDate}>
                                <div>{day}</div>
                                <div>{time}</div>
                            </div>
                        )}

                        {viewType !== 'mobile' && (
                            <div className={style.textInfo}>{date}</div>
                        )}

                        {type === 'spisanie'? <div className={style.icon}><Minus/></div>: <div className={style.icon}><Plus/></div>}
                        <div className={style.bodyContainer}>
                            <div className={style.sum}>{pay_sum}</div>
                            {viewType !== 'mobile' && (
                                <div className={style.textInfo}>{currency}</div>
                            )}

                        </div>
                        <div className={style.bodyContainer}>
                            <div className={style.sum}>{balance}</div>
                            {viewType !== 'mobile' && (
                                <div className={style.textInfo}>{currency}</div>
                            )}
                        </div>
                        {CheckLink !== null && (
                                <a className={style.textInfo} href={CheckLink} target="_blank">Пополнение через АльфаБанк</a>
                            // <div onClick={() => {showWindowCheck(CheckLink)}}>Ссылка на чек</div>
                        )}
                        {CheckLink === null && (
                            <div className={style.textInfo}>{comment}</div>
                        )}

                    </div>
                )
            })}
        </DropDown>
    );
}

export default isAdaptive(Statistics)