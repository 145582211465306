import React, { useCallback, useState } from 'react';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';

import Input from '../../../../common/Input';
import Label from '../../../../common/Label';
import Button from '../../../../common/Button';
import { useActions } from '../../../../../hooks/useActions';
import { IFriendDataObject, NewUserDataItem } from '../../../../../types/connectNewUser';

import style from './GoFriends.module.css';


const ConnectNewFriend = () => {

    const [token, setToken] = useState('');
    const [visible, setVisible] = useState(true);
    const handleChallengeHidden = useCallback(() => setVisible(false), []);
    const [errorArrayField, setErrorArrayField] = useState<string[]>([]);

    const { connectNewFriend, hideWindow } = useActions();
    const data: NewUserDataItem[]  = [
        {
            objectKey: 'friend_name',
            label: 'ФИО друга',
        },
        {
            objectKey: 'friend_address',
            label: 'Адрес друга'
        },
        {
            objectKey: 'friend_phone',
            label: 'Телефон друга'
        },
        {
            objectKey: 'friend_email',
            label: 'E-mail друга'
        },
        {
            objectKey: 'you_name',
            label: 'Ваше ФИО'
        },
        {
            objectKey: 'you_phone',
            label: 'Ваш телефон'
        },
        {
            objectKey: 'you_login',
            label: 'Ваш логин'
        },
        {
            objectKey: 'you_address',
            label: 'Ваш адрес'
        },
    ];

    const [friendInfo, setFriendInfo] = useState((): IFriendDataObject => {
        return data.reduce((acc, value) => {
            //@ts-ignore
            acc[value.objectKey] = ''
            return acc;
        }, {comment: ''}) as IFriendDataObject;
    });


    const handleInputValue = (inputValue: string, name: string) => {
        setFriendInfo({
            ...friendInfo,
            [name]:  inputValue
        })
    };

    const handleSubmitFriendInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const array =  [];

        for (let key in friendInfo){
            //@ts-ignore
            if (friendInfo[key] === '') {
                array.push(key)
            }
        }
        if (array.length === 0) {
            connectNewFriend({
                message: friendInfo,
                token: token,
                path: 'siteRequestNewFriend'
            })
            hideWindow();
        }

        setErrorArrayField(array);



    }

    const handleFocusOnBlur = (name: string) => {

        const errorBlur =  [...errorArrayField];
        let indexField = errorBlur.indexOf(name);

        // @ts-ignore
        const value = friendInfo[name]
        if (value === '' && indexField === -1) {
            errorBlur.push(name)
        }
        if(value !== ''){
            errorBlur.splice(indexField, 1)
        }

        setErrorArrayField(errorBlur);

    }


    return (
      <>
          <form className={style.root}>
              <div className={style.infoContainer}>
                  {data.map(el => (
                      <div key={el.objectKey}>
                          <Label title={el.label}>
                              <Input onChange={handleInputValue} name={el.objectKey} onBlur={handleFocusOnBlur} />
                              {errorArrayField.includes(el.objectKey) && (<div className={style.alert}>{'Обязательное поле не заполнено!'}</div>)}
                          </Label>
                      </div>
                  ))}
              </div>
              <div className={style.comment}>
                  <Label title={'Коментарий'}>
                      <Input onChange={handleInputValue} name={'comment'} onBlur={handleFocusOnBlur} />
                      {errorArrayField.includes('comment') && (<div className={style.alert}>{'Обязательное поле не заполнено!'}</div>)}
                  </Label>
              </div>
              <div className={style.buttonContainer}>
                  <Button className={style.button} size={'large'} type={'submit'} onClick={handleSubmitFriendInfo}>
                      Отправить
                  </Button>
              </div>
          </form>
          <InvisibleSmartCaptcha
              sitekey={process.env.REACT_APP_CAPTCHA_KEY || '123' }
              onSuccess={setToken}
              onChallengeHidden={handleChallengeHidden}
              visible={visible}
          />
      </>
    )
}

export default ConnectNewFriend;
