export const getClassName = obj => {
    return Object.entries(obj).reduce((acc, current, index) => {
        const ident = index > 0 ? ' ' : '';

        if (current[1]) {
            acc += `${ident}${current[0]}`
        }

        return acc;
    }, '');
};

