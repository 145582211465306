import { DocumentsState, DocumentsAction, DocumentsActionTypes} from "../../types/documents";

const initialState: DocumentsState = {
    data: {
        count: 0,
        documents: []
    },
    loading: false,
    error: null
}

export const documentsReducer = (state = initialState, action: DocumentsAction): DocumentsState => {
    switch (action.type){
        case DocumentsActionTypes.FETCH_DOCUMENTS:
            return { ...state, loading: true}
        case DocumentsActionTypes.FETCH_DOCUMENTS_SUCCESS:
            return { ...state, loading: false, data: action.payload}
        case DocumentsActionTypes.FETCH_DOCUMENTS_ERROR:
            return { ...state, loading: false, error: action.payload}
        default:
            return state;
    }
}
