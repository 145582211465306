import { VIEW_TYPE_DESKTOP, VIEW_TYPE_MOBILE, VIEW_TYPE_TABLET } from '../contsants';
import React from 'react';

interface WithThemeProps {
    viewType: string;
}

export function isAdaptive<T>(WrappedComponent: React.ComponentType<T>) {

    const size = window.screen.width;
    let viewType: string;
    if (size >= 320 && size <= 500) {
        viewType = VIEW_TYPE_MOBILE;
    } else if (size >= 768 && size <= 1023 ) {
        viewType = VIEW_TYPE_TABLET;
    } else if (size >= 1024) {
        viewType = VIEW_TYPE_DESKTOP;
    }


    const ComponentWithTheme = (props: Omit<T, keyof WithThemeProps>) => {
        return <WrappedComponent viewType={viewType} {...(props as T)} />;
    };

    return ComponentWithTheme;
}



