import React from 'react';

import style from './AuthInfo.module.css'

export default function AuthInfo () {
    return (
        <div className={style.info}>
            <div className={style.enterText}>Вход в личный кабинет</div>
            <div className={style.check}>Укажите номер лицевого счета и пароль</div>
        </div>
    )
}
