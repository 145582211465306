import React from 'react';

import { getClassName } from '../../../utils/className';

import style from './SwitchToggle.module.css';

interface ISwitch {
    onClick: (id: string | number ) => void;
    id: string | number;
    enable: boolean;
    value: boolean;
}

const SwitchToggle: React.FC<ISwitch> = (
    {
        onClick,
        value,
        id,
        enable
    }) => {

    const handleToggle = () => {
        if (onClick) {
            onClick(id)
        }
    }

    const clsLabel = getClassName({
        [style.switchLabel]: true,
        [style.switchOn]: value,
        [style.switchDisable]: !enable
    })

    const clsButton = getClassName({
        [style.switchButton]: true,
        [style.switchActive]: value,
    })

    return (
        <div className={clsLabel} onClick={handleToggle}>
            <div className={clsButton} />
        </div>
    );
}

export default SwitchToggle;

