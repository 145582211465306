import React from 'react';

import { getClassName } from '../../../utils/className';

import style from './CkeckBox.module.css'

type ICheckBox = {
    onChange: () => void;
    text: string;
    checked: boolean;
    disabled?: boolean;
    textColor:boolean;
    id?: string
}

const CheckBox: React.FC<ICheckBox> = (
    {
        onChange,
        text,
        checked,
        disabled,
        textColor,
        id
    }) => {

    const handleChange = () => {
        if (onChange) {
            onChange()
        }
    }

    const cls = getClassName({
        [style.root]: true,
        [style.textColor]: textColor,
    })

    return (
        <div className={cls}>
            <input
                type="checkbox"
                id={id}
                onChange={handleChange}
                checked={checked}
                disabled={disabled}
            />
            <label  htmlFor={id}>{text}</label>
        </div>
    )
}

export default CheckBox;
