const actionTypes =  {
    FETCH_TV_PROVIDERS: 'FETCH_TV_PROVIDERS',
    FETCH_TV_PROVIDERS_SUCCESS: 'FETCH_TV_PROVIDERS_SUCCESS',
    FETCH_TV_PROVIDERS_ERROR: 'FETCH_TV_PROVIDERS_ERROR'
}

const initialState = {
    data: {
        providers: []
    },
    loading: false,
    error: null
}

export const tvProvidersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_TV_PROVIDERS:
            return {...state, loading: true}
        case actionTypes.FETCH_TV_PROVIDERS_SUCCESS:
            return {...state, loading: false, data: action.payload}
        case actionTypes.FETCH_TV_PROVIDERS_ERROR:
            return {...state, error: action.payload}
        default:
            return state;
    }
}

export default tvProvidersReducer;