import { WindowActionTypes, WindowAction, WindowState } from '../../types/window';

const initialState: WindowState =  {
    show: false,
    content: null,
    title: '',
    showCloseButton: true,
    header: true,
    scrollBlock: false,
}

export const windowReducer = (state: WindowState = initialState, action: WindowAction): WindowState =>  {

    switch (action.type) {
        case WindowActionTypes.SHOW_WINDOW : {
            return {
                ...state,
                ...action.payload
            }
        }
        case WindowActionTypes.HIDE_WINDOW : {
            return {
                ...initialState
            }
        }
        default: {
            return state;
        }
    }
}

