import axios from 'axios';

interface Path {
    path: string;
    message: string;
    token: string;
}
export const sendInfo = (params: Path) => {
    return () => {
        return axios
            .post(`/api/site/send_info/${params.path}?token=${params.token}`, params.message)
            .then( () => {

            })
    }
}
