import axios from './../../utils/axios';
import { Dispatch } from 'redux';
import { OneNewsAction, OneNewsActionTypes } from '../../types/oneNews';

export const oneNews = (newsId: number) => {
    return async (dispatch: Dispatch<OneNewsAction>) => {
        try {
            dispatch({type: OneNewsActionTypes.ONE_NEWS})
            const response = await axios.get(`/api/site/get_one_news/${newsId}`)
            dispatch({
                type: OneNewsActionTypes.ONE_NEWS_SUCCESS,
                payload: response.data
            })
        } catch (error) {
            dispatch({
                type: OneNewsActionTypes.ONE_NEWS_ERROR,
                payload: 'Ошибка при загрузке новости'
            })
        }
    }
}
