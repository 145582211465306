import axios from 'axios';


const actionTypes =  {
    FETCH_TV_PROVIDERS: 'FETCH_TV_PROVIDERS',
    FETCH_TV_PROVIDERS_SUCCESS: 'FETCH_TV_PROVIDERS_SUCCESS',
    FETCH_TV_PROVIDERS_ERROR: 'FETCH_TV_PROVIDERS_ERROR'
}
export const fetchTvProvider = () => {
    return async (dispatch) => {
        try {
            dispatch({type: actionTypes.FETCH_TV_PROVIDERS})
            const response = await axios.get('/api/tv/tv_get_provider')
            dispatch({type: actionTypes.FETCH_TV_PROVIDERS_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({
                type: actionTypes.FETCH_TV_PROVIDERS_ERROR,
                payload: 'Ошибка при загрузке списка'
            })
        }
    }
}