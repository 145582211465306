import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../hooks/useActions';

import Button from '../../common/Button/Button';
import IconButton from '../../common/IconButton/IconButton';
import PromisedPaymentWindow from './component/PromisedPaymentWindow/PromisedPaymentWindow';
import Payment from './component/Payment/Payment';
import WhiteIp from './component/WhiteIp/WhiteIp';
import { ReactComponent as Rub } from '../../../static/images/₽.svg';
import { isAdaptive } from '../../../hoc/isAdaptive';
import Update from '../../../static/images/update.png'

import style from "./Balance.module.css"


function Balance({ children, viewType }) {

    const { showWindow, getBalance } = useActions();

    const { money, promised_pay_allow, balanceMessage } = useSelector(state => state.baseInfo.info)
    const [balance, setBalance] = useState(money)


    const showWindowIp = () => {
        const content = <WhiteIp/>;
        showWindow({
            content,
            title: "Статический IP",
            showCloseButton: true,
            header: true,
        })
    }

    const showWindowClick = () => {
        const content = <PromisedPaymentWindow/>;
        showWindow({
            content,
            showCloseButton: false,
            header: false,
        })
    }

    const showWindowClickPayment = () => {
        const content = <Payment/>;
        showWindow({
            content,
            title: 'Пополнить лицевой счет',
            showCloseButton: true,
            header: true,
        })
    }


    const updateBalance = () => {
        getBalance().then(res => {
            if (res.status === 200) {
                setBalance(res.data.balance)
            }
        })

    }



    return(
        <>
            <div className={style.root}>
                <div className={style.moneyBox}>
                    {viewType === 'mobile' && (
                        <div className={style.balanceMobile}>
                            <div>Баланс</div>
                            <div className={style.balance}>
                                {`${balance} `}
                            </div>
                        </div>
                    )}
                    {viewType !== 'mobile' && (
                        <div className={style.balanceTablet}>
                            <IconButton>
                                <Rub/>
                            </IconButton>
                            <div className={style.balance}>
                                {`${balance} `}
                            </div>
                        </div>

                    )}
                    <img className={style.update} src={Update} alt="Обновить" onClick={updateBalance} />
                </div>

                <div className={style.moneyButton}>
                    <div>
                        <Button onClick={showWindowIp} theme="secondary" size={'large'}>
                            Белый&nbsp;IP
                        </Button>
                    </div>
                    <div>
                        <Button onClick={showWindowClick}  theme="secondary" size={'large'} activeButtonWhite={!promised_pay_allow}>
                            Обещанный&nbsp;платеж
                        </Button>
                    </div>
                    <div>
                        <Button onClick={showWindowClickPayment} size={'large'}>
                            Оплатить
                        </Button>
                    </div>
                </div>
            </div>
            <div className={style.balanceMessage}>{balanceMessage}</div>
        </>


    );
}

export default isAdaptive(Balance)

