import React from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../../hooks/useActions';

import { ReactComponent as Pencil } from '../../../../../static/images/Pencil.svg';
import TvTariffPlane from './component/TvTariffPlane/TvTariffPlane';

import style from './TariffPlane.module.css'
import styles from '../../../../Site/components/Tariffs/components/TariffItem/TariffItem.module.css';


export default function TariffPlane () {

    const { showWindow } = useActions();
    const tariff = useSelector(state => state.baseInfo.info.tariff);

    if (!tariff) {
        return;
    }

    const { name, price_type, price, speed } = tariff;

    const showTariffPlane = () => {
        const content = <TvTariffPlane/>
        showWindow({
            content,
            title: 'Тарифные планы',
            showCloseButton: true,
            header: true,
        })
    }

    return (
        <div className={style.root}>
            <div className={style.tariffContainer} onClick={showTariffPlane}>
                <div className={style.tariffPencil}>
                    <div className={style.tariffName}>Тарифный план</div>
                    <Pencil/>
                </div>
                <div className={style.tariffSpeed}>{name}</div>
                <div className={style.tariffMoney}>
                    <div className={style.moneyCount}>{price}</div>
                    <div>руб / {price_type === 'day'? 'сутки' : 'месяц'}</div>
                </div>
                <div className={styles.speed}>
                    {`Скорость ${speed}`}
                </div>
            </div>
            <div className={style.tariffCount}>
                <div className={style.line}></div>
            </div>
        </div>
    );
}
