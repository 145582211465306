import axios from './../../utils/axios';
import { Dispatch } from 'redux';
import { FetchTVResponse, FetchPackageResponse, ChannelsAction, ChannelsActionTypes } from '../../types/channels';

export const fetchTV =  () => {
    return async (dispatch: Dispatch<ChannelsAction>) => {
        try  {
            dispatch({type: ChannelsActionTypes.FETCH_TV})
            const response = await axios.get<FetchTVResponse>('/api/tv/get_default_tv')
            dispatch({type: ChannelsActionTypes.FETCH_TV_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({
                type: ChannelsActionTypes.FETCH_TV_ERROR,
                payload: 'Ошибка при загрузке списка'
            })
        }
    }
}

export const fetchPackage =  (packageId: number) => {
    return async (dispatch: Dispatch<ChannelsAction>) => {
        try  {
            dispatch({type: ChannelsActionTypes.FETCH_PACKAGE})
            const response = await axios.get<FetchPackageResponse>(`/api/site/get_chanels/${packageId}`)
            dispatch({type: ChannelsActionTypes.FETCH_PACKAGE_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({
                type: ChannelsActionTypes.FETCH_PACKAGE_ERROR,
                payload: 'Ошибка при загрузке списка'
            })
        }
    }
}
