import axios from './../../utils/axios';

export const getBalance = () => {
    return async () => {
        try {
            return  await axios.get(`/api/lk/balance`)
                .then((response) => response)
        }
        catch (error) {

        }
    }
}