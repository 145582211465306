import { OneNewsAction, OneNewsActionTypes, OneNewsState } from '../../types/oneNews';


const initialState: OneNewsState = {
    data: {
        title: '',
        id: 0,
        text: '',
        icon_url: '',
        date: ''
    },
    loading: false,
    error: null
}

export const oneNewsReducer = (state = initialState, action: OneNewsAction): OneNewsState => {
    switch (action.type) {
        case OneNewsActionTypes.ONE_NEWS:
            return {...state, loading: true}
        case OneNewsActionTypes.ONE_NEWS_SUCCESS:
            return {...state, loading: false, data: action.payload}
        case OneNewsActionTypes.ONE_NEWS_ERROR:
            return {...state, loading: false, error: action.payload}
        default:
            return state

    }

}
