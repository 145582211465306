import axios from '../../utils/axios';

const actionTypes = {
    TARIFF: 'TARIFF',
    TARIFF_SUCCESS: 'TARIFF_SUCCESS',
    TARIFF_ERROR: 'TARIFF_ERROR',
}

export const tariffInfo = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.TARIFF,
        })
        return axios
            .get('/api/lk/tariff')
            .then(res => {
                dispatch({
                    type: actionTypes.TARIFF_SUCCESS,
                    payload: {
                        status: res.data.status,
                        count: res.data.count,
                        message: res.data.message,
                        tariffs: res.data.tariffs,
                        text: res.data.message.text,
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.TARIFF_ERROR,
                    payload: {
                        message: error.payload.message
                    }
                })
            })
    }
}

export const changeTariffInfo = (params) => {
    return (dispatch) => {
        return axios
            .post('/api/lk/tariff', {
                tariff_id: params.tariff_id,
                start_date: params.start_date,
                now: params.now,
        })
            .then(res => {
                // dispatch(baseInfo())
                dispatch({
                    type: actionTypes.TARIFF_SUCCESS,
                    payload: {
                        status: res.data.status,
                        count: res.data.count,
                        message: res.data.message,
                        tariffs: res.data.tariffs,
                        text: res.data.message.text
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.TARIFF_ERROR,
                    payload: {
                        message: error.payload.message
                    }
                })
            })
    }
}
