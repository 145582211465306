export interface LayoutState {
    contacts: FetchContactsResponse;
    copyright: FetchCopyrightResponse;
    loading: boolean;
    error: null | string;
}

export interface FetchContactsResponse {
    address: string,
    email: string,
    coordinates: ICoords,
    phones: IPhone[],
    url: string,
    messenger: IMessenger[],
    ur_info: IUrInfo
}
export interface IPhone {
    url: string,
    string: string
}

export interface IUrInfo {
    inn: number,
    kpp: number,
    name: string,
    ogrn: number
}

export interface ICoords {
    lat: number,
    lon: number
}

export interface IMessenger {
    url: string,
    icon: string,
}
export interface FetchCopyrightResponse {
    copyright: string,
}

export enum LayoutActionTypes {
    FETCH_CONTACTS = 'FETCH_CONTACTS',
    FETCH_CONTACTS_SUCCESS  = 'FETCH_CONTACTS_SUCCESS',
    FETCH_CONTACTS_ERROR = 'FETCH_CONTACTS_ERROR',
    FETCH_COPYRIGHT = 'FETCH_COPYRIGHT',
    FETCH_COPYRIGHT_SUCCESS  = 'FETCH_COPYRIGHT_SUCCESS',
    FETCH_COPYRIGHT_ERROR = 'FETCH_COPYRIGHT_ERROR',
}

interface FetchContactsAction {
    type: LayoutActionTypes.FETCH_CONTACTS
}

interface FetchContactsSuccessAction {
    type: LayoutActionTypes.FETCH_CONTACTS_SUCCESS
    payload: FetchContactsResponse
}

interface FetchContactsErrorAction {
    type: LayoutActionTypes.FETCH_CONTACTS_ERROR
    payload: string
}
interface FetchCopyrightAction {
    type: LayoutActionTypes.FETCH_COPYRIGHT
}

interface FetchCopyrightSuccessAction {
    type: LayoutActionTypes.FETCH_COPYRIGHT_SUCCESS
    payload: FetchCopyrightResponse
}

interface FetchCopyrightErrorAction {
    type: LayoutActionTypes.FETCH_COPYRIGHT_ERROR
    payload: string
}

export interface IIds {
    show: boolean
}


export type LayoutAction = FetchContactsAction | FetchContactsSuccessAction | FetchContactsErrorAction
    | FetchCopyrightAction | FetchCopyrightSuccessAction | FetchCopyrightErrorAction;
