import React from 'react';

import { useTypedSelector } from '../../../../hooks/useTypedSelector';

import styles from './Footer.module.css'


const Footer: React.FC = () => {

    const { copyright } = useTypedSelector(state => state.layout)

    return (
            <div className="container">
                <div className={styles.container}>
                    © {copyright.copyright}
                </div>
            </div>
    );
}
export default Footer
