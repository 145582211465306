import React from 'react';

import { getClassName } from '../../../utils/className';

import style from './Label.module.css'

interface ILabel {
    title: string;
    theme?: string;
    size?: string;
    children: React.ReactNode;
}

const  Label: React.FC<ILabel> = (
    {
        title,
        children,
        theme= 'primary',
        size = 'large'
    }) => {

    const cls = getClassName({
        [style.root]: true,
        [style[theme]]: true,
        [style[size]]: true,
    })

    return (
        <>
            <div className={cls} >{ title }</div>
            { children }
        </>
    )
}

export default Label;
