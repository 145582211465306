import React, { useEffect } from "react";

import Title from '../../../common/Title';
import ChannelItem from './components/ChannelItem';
import { useActions } from '../../../../hooks/useActions';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';

import styles from './Channels.module.css'

const Channels: React.FC = () => {
    const { fetchTV } = useActions();
    const { packages } = useTypedSelector(state => state.channels);

    useEffect(()=> {
        fetchTV()
    },[])


    return (
        <>
            <Title>Цифровое&nbsp;ТВ</Title>
            <div className={styles.container}>
                {packages.packets && packages.packets.map(
                    ({providerId, name, price, price_type, info}) => (
                    <ChannelItem
                        key={providerId}
                        name={name}
                        price={price}
                        price_type={price_type}
                        info={info}
                        providerId={providerId}
                    />
                ))}
            </div>
        </>
    );
}
export default Channels
