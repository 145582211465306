export interface DocumentsState {
    data: FetchDocumentsResponse;
    loading: boolean;
    error: null | string;
}

export interface FetchDocumentsResponse {
    count: number;
    documents: IDocuments []
}
export interface IDocuments {
    name: number,
    count: string,
    content: IContent[],
    id: number
}

interface IContent {
    url: string,
    icon_url: string,
    title: string,
    id: number
}

export enum DocumentsActionTypes {
    FETCH_DOCUMENTS = 'FETCH_DOCUMENTS',
    FETCH_DOCUMENTS_SUCCESS  = 'FETCH_DOCUMENTS_SUCCESS',
    FETCH_DOCUMENTS_ERROR = 'FETCH_DOCUMENTS_ERROR',
}

interface FetchDocumentsAction {
    type: DocumentsActionTypes.FETCH_DOCUMENTS
}

interface FetchDocumentsSuccessAction {
    type: DocumentsActionTypes.FETCH_DOCUMENTS_SUCCESS
    payload: FetchDocumentsResponse
}

interface FetchDocumentsErrorAction {
    type: DocumentsActionTypes.FETCH_DOCUMENTS_ERROR
    payload: string
}

export type DocumentsAction = FetchDocumentsAction | FetchDocumentsSuccessAction | FetchDocumentsErrorAction;
